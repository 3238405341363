import { buildEnumValueConfig, buildProperty, EnumValueConfig } from 'firecms';
import {
  AvailabilitySchedule,
  EarningsDepositsAccount,
  PlaceType,
} from '../models/place';
import {
  cuisineTypes,
  descriptionPhoneNumberCameroon,
  NetworkOperator,
  OrderOptionName,
  PaymentGateway,
  SocialNetwork,
} from '../utils/constants';
import { regexPhoneNumberCameroon } from '../utils/regexs';
import {
  CategoriesPathFields,
  CategoriesPathPreView,
} from '../widgets/categories/categories_utils';
import CustomListOfAddOnItem from '../widgets/custom_add_ons_widget';
import {
  AvailabilityDays,
  MenuAvailabilityField,
} from '../widgets/menu_availability_widget';
import { TablesFields, TablesPreView } from '../widgets/place_table';
import {
  ServiceFeesComputationVersionFields,
  ServiceFeesComputationVersionPreview,
} from '../widgets/places/service_fees_computation_version';
import {
  CustomSubscriptionPathField,
  PreviewSubscriptionPathField,
} from '../widgets/subscription_path_field';

const cuisineTypesEnumValues: EnumValueConfig[] = cuisineTypes
  .sort()
  .map((cuisineType) =>
    buildEnumValueConfig({ id: cuisineType, label: cuisineType })
  );

const enumPaymentGateways: EnumValueConfig[] = Object.entries(
  PaymentGateway
).map(([key, value]) => {
  const id = value;
  const label = key;
  return { id, label };
});

const enumPlaceType: EnumValueConfig[] = Object.entries(PlaceType).map(
  ([key, value]) => {
    const id = value;
    const label = key;
    return { id, label };
  }
);

export const placeSchema = {
  brandName: buildProperty<string>({
    name: 'Brand Name',
    validation: { required: true, trim: true },
    dataType: 'string',
  }),
  description: buildProperty<string>({
    name: 'Description',
    validation: { required: false, trim: true },
    dataType: 'string',
    multiline: true,
  }),
  isActive: buildProperty<boolean>({
    name: 'Active',
    validation: { required: true },
    dataType: 'boolean',
  }),
  address: buildProperty<Record<string, unknown>>({
    name: 'Address',
    validation: { required: true },
    description: 'Address of the restaurant',
    dataType: 'map',
    properties: {
      street: buildProperty<string>({
        name: 'Street',
        validation: { required: true, trim: true },
        dataType: 'string',
      }),
      city: buildProperty<string>({
        name: 'City',
        validation: { required: true, trim: true },
        dataType: 'string',
        enumValues: {
          Douala: 'Douala',
          Yaounde: 'Yaounde',
        },
      }),
      country: buildProperty<string>({
        name: 'Country',
        validation: { required: true, trim: true },
        dataType: 'string',
        enumValues: {
          Cameroon: 'Cameroon',
        },
      }),
      lat: buildProperty<number>({
        name: 'Latitude',
        validation: {
          required: true,
          min: -90,
          max: 90,
        },
        dataType: 'number',
      }),
      lng: buildProperty<number>({
        name: 'Longitude',
        validation: {
          required: true,
          min: -180,
          max: 180,
        },
        dataType: 'number',
      }),
      postalCode: buildProperty<string>({
        name: 'Postal Code',
        validation: { required: false, trim: true },
        dataType: 'string',
      }),
      additionalInformation: buildProperty<string>({
        name: 'Additional Information',
        validation: { required: false, trim: true },
        dataType: 'string',
        multiline: true,
      }),
    },
  }),
  cuisineType: buildProperty<Array<string>>({
    name: 'Cuisine Types',
    dataType: 'array',
    validation: { required: true },
    of: {
      dataType: 'string',
      enumValues: cuisineTypesEnumValues,
    },
  }),
  categoriesPaths: buildProperty<Array<string>>({
    name: 'Categories',
    dataType: 'array',
    of: {
      dataType: 'string',
    },
    validation: { required: true },
    Field: CategoriesPathFields,
    Preview: CategoriesPathPreView,
  }),
  orderOptions: buildProperty<Array<string>>({
    name: 'Order Options',
    dataType: 'array',
    validation: { required: true },
    of: {
      dataType: 'string',
      enumValues: {
        takeAway: OrderOptionName.TakeAway,
        dineIn: OrderOptionName.DineIn,
        delivery: OrderOptionName.Delivery,
      },
    },
  }),
  paymentGateways: buildProperty<Array<string>>({
    name: 'Payments Gateways',
    dataType: 'array',
    of: {
      dataType: 'string',
      enumValues: enumPaymentGateways,
    },
  }),
  subscriptionPath: buildProperty<string>({
    name: 'Subscription Path',
    dataType: 'string',
    validation: { required: false },
    Field: CustomSubscriptionPathField,
    Preview: PreviewSubscriptionPathField,
  }),
  subscriptionDeposits: buildProperty({
    name: 'Subscription Deposits',
    dataType: 'array',
    validation: {
      required: false,
    },
    of: {
      dataType: 'map',
      properties: {
        amountInXAF: {
          name: 'Amount In XAF',
          validation: { required: true, min: 0 },
          dataType: 'number',
        },
        createdAt: {
          name: 'Created At',
          dataType: 'date',
          mode: 'date_time',
          validation: { required: true },
        },
      },
    },
  }),
  tables: buildProperty<Record<string, unknown>>({
    name: 'Tables',
    dataType: 'map',
    Preview: TablesPreView,
    Field: TablesFields,
  }),
  openingTimes: buildProperty<Record<string, unknown>>({
    name: 'Opening Times',
    validation: { required: true },
    dataType: 'map',
    properties: {
      openingDay: {
        name: 'Opening Days',
        validation: { required: true },
        dataType: 'array',
        of: {
          dataType: 'map',
          properties: {
            day: {
              name: 'Opening Day',
              validation: {
                required: true,
                min: 1,
                max: 7,
              },
              description: '1 represent monday, 7 represent sunday',
              dataType: 'number',
            },
            openingPeriods: {
              name: 'Opening Periods',
              validation: { required: true },
              dataType: 'array',
              of: {
                dataType: 'map',
                properties: {
                  openTimeInUTC: {
                    name: 'Open Time (UTC)',
                    validation: { required: true },
                    dataType: 'map',
                    properties: {
                      hour: {
                        name: 'Hour',
                        validation: {
                          required: true,
                          requiredMessage: 'Set hours between 0 and 23',
                          min: 0,
                          max: 23,
                        },
                        dataType: 'number',
                      },
                      minute: {
                        name: 'Minute',
                        validation: {
                          required: true,
                          requiredMessage: 'Set minutes between 0 and 59',
                          min: 0,
                          max: 59,
                        },
                        dataType: 'number',
                      },
                    },
                  },
                  closeTimeInUTC: {
                    name: 'Close Time (UTC)',
                    validation: { required: true },
                    description: 'Closing time of the restaurant in UTC',
                    dataType: 'map',
                    properties: {
                      hour: {
                        name: 'Hour',
                        validation: {
                          required: true,
                          requiredMessage: 'Set hours between 0 and 23',
                          min: 0,
                          max: 23,
                        },
                        dataType: 'number',
                      },
                      minute: {
                        name: 'Minute',
                        validation: {
                          required: true,
                          requiredMessage: 'Set minutes between 0 and 59',
                          min: 0,
                          max: 59,
                        },
                        dataType: 'number',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  }),
  images: buildProperty<Record<string, unknown>>({
    name: 'Images',
    validation: { required: true },
    dataType: 'map',
    properties: {
      thumbPath: buildProperty<string>({
        name: 'Thumb',
        validation: { required: true },
        dataType: 'string',
        storage: {
          storagePath: 'restaurants/thumbs',
          acceptedFiles: ['image/*'],
        },
      }),
      bannerPath: buildProperty<string>({
        name: 'Banner',
        validation: { required: true },
        dataType: 'string',
        storage: {
          storagePath: 'restaurants/banners',
          acceptedFiles: ['image/*'],
        },
      }),
      previewsPath: buildProperty<Array<string>>({
        name: 'Previews',
        validation: { required: true },
        dataType: 'array',
        of: buildProperty<string>({
          dataType: 'string',
          storage: {
            storagePath: 'restaurants/previews',
            acceptedFiles: ['image/*'],
          },
        }),
      }),
    },
  }),
  earningsDepositsAccounts: buildProperty<EarningsDepositsAccount>({
    name: 'Earnings Deposits Accounts',
    dataType: 'map',
    properties: {
      earningsDepositsNumbers: {
        name: 'Earnings Deposits Numbers',
        validation: { uniqueInArray: true },
        dataType: 'array',
        of: buildProperty<Record<string, string>>({
          dataType: 'map',
          properties: {
            phoneNumber: {
              name: 'Phone Number',
              validation: {
                required: true,
                matches: regexPhoneNumberCameroon,
              },
              description: descriptionPhoneNumberCameroon,
              dataType: 'string',
            },
            networkOperator: {
              name: 'Network Operator',
              validation: {
                required: true,
              },
              dataType: 'string',
              enumValues: NetworkOperator,
            },
          },
        }),
      },
    },
  }),
  contacts: buildProperty<Record<string, unknown>>({
    name: 'Contacts',
    validation: { required: true },
    dataType: 'map',
    properties: {
      phoneNumbers: {
        name: 'Phone Numbers',
        validation: { required: true },
        dataType: 'array',
        of: {
          dataType: 'string',
        },
      },
      smsNotifications: buildProperty<Array<Record<string, unknown>>>({
        name: 'SMS Notifications Numbers',
        description: descriptionPhoneNumberCameroon,
        dataType: 'array',
        validation: {
          required: true,
        },
        of: buildProperty<string>({
          dataType: 'string',
          validation: {
            required: true,
            matches: regexPhoneNumberCameroon,
          },
        }),
      }),
      emails: buildProperty<Array<string>>({
        name: 'Emails',
        validation: { required: true },
        dataType: 'array',
        of: buildProperty<string>({
          dataType: 'string',
          email: true,
        }),
      }),
      website: buildProperty<string>({
        name: 'Website',
        validation: { required: false, trim: true },
        dataType: 'string',
        url: true,
      }),
      socials: buildProperty<Record<string, unknown>>({
        name: 'Socials',
        dataType: 'map',
        properties: {
          name: {
            dataType: 'string',
            enumValues: {
              facebook: SocialNetwork.Facebook,
              instagram: SocialNetwork.Instagram,
              twitter: SocialNetwork.Twitter,
            },
          },
          uris: {
            dataType: 'array',
            of: {
              dataType: 'string',
              url: true,
            },
          },
        },
      }),
    },
  }),
  serviceFeesComputationVersion: buildProperty<string>({
    name: 'Version Fees Computation ',
    validation: { required: true },
    dataType: 'string',
    Field: ServiceFeesComputationVersionFields,
    Preview: ServiceFeesComputationVersionPreview,
  }),
  placeType: buildProperty<string>({
    name: 'place Type',
    dataType: 'string',
    validation: { required: true },

    enumValues: enumPlaceType,
  }),
  webDeepLink: buildProperty<string>({
    name: 'Web Deep Link',
    readOnly: true,
    validation: {
      required: false,
    },
    dataType: 'string',
    url: true,
  }),
};

export const addOnGroupsSchema = {
  description: buildProperty<string>({
    name: 'Group Name',
    validation: { required: true, unique: true, trim: true, max: 100 },
    dataType: 'string',
  }),
  required: buildProperty<boolean>({
    defaultValue: false,
    validation: {
      required: true,
    },
    name: 'Required',
    dataType: 'boolean',
  }),
  multiSelect: buildProperty<boolean>({
    defaultValue: false,
    validation: {
      required: true,
    },
    name: 'Allow Multiple Selections',
    dataType: 'boolean',
  }),
};

export const addOnItemSchema = {
  addOnItem: buildProperty<string>({
    name: 'AddOn Item',
    dataType: 'string',
    validation: { unique: true, required: true, trim: true },
    Field: CustomListOfAddOnItem,
  }),
  additionalPriceInXAF: buildProperty<number>({
    name: 'Additional Price in XAF',
    dataType: 'number',
    defaultValue: 0,
    description: 'Sets the price for this menu.',
  }),
  additionalNonDiscountedPriceInXAF: buildProperty<number>({
    name: 'Extra Non Discounted Price in XAF',
    dataType: 'number',
    defaultValue: 0,
    description:
      'Sets the price for this menu before a discount promotion takes place.',
  }),
  isAvailable: buildProperty<boolean>({
    name: 'Available',
    dataType: 'boolean',
    defaultValue: false,
    validation: {
      required: true,
    },
    description: 'Allows the addon to be available in this menu.',
  }),
};

export const menuItemSchema = {
  foodName: buildProperty<string>({
    name: 'Food Name',
    validation: { required: true, trim: true, max: 30 },
    dataType: 'string',
  }),
  shortDescription: buildProperty<string>({
    name: 'Description',
    validation: { required: true, trim: true, max: 100 },
    dataType: 'string',
  }),
  longDescription: buildProperty<string>({
    name: 'Long Description',
    validation: { required: false, trim: true, max: 400 },
    dataType: 'string',
  }),
  foodType: buildProperty<string>({
    name: 'Food Type',
    validation: { required: true },
    dataType: 'string',
    enumValues: cuisineTypesEnumValues,
  }),
  imagePath: buildProperty<string>({
    name: 'Image',
    validation: { required: false },
    dataType: 'string',
    storage: {
      storagePath: 'restaurants/menu_items',
      acceptedFiles: ['image/*'],
    },
  }),
  numberOfPerson: buildProperty<number>({
    name: 'Number Of Persons',
    dataType: 'number',
    validation: {
      required: true,
      min: 1,
    },
  }),
  priceInXAF: buildProperty<number>({
    name: 'Price (XAF)',
    dataType: 'number',
    validation: {
      required: true,
      min: 1,
    },
  }),
  nonDiscountedPriceInXAF: buildProperty<number>({
    name: 'Non Discounted Price (XAF)',
    dataType: 'number',
    validation: {
      required: false,
      min: 1,
    },
  }),
  packageFee: buildProperty<number>({
    name: 'Packaging Fee (XAF)',
    dataType: 'number',
    validation: {
      required: true,
      min: 0,
    },
  }),
  isVegetarian: buildProperty<boolean>({
    name: 'Vegetarian',
    dataType: 'boolean',
    validation: { required: true },
  }),
  isAvailable: buildProperty<boolean>({
    name: 'Available',
    dataType: 'boolean',
    validation: { required: true },
  }),
  isVisible: buildProperty<boolean>({
    name: 'Visible',
    dataType: 'boolean',
    defaultValue: true,
    validation: { required: true },
  }),
  availabilitySchedule: buildProperty<Partial<AvailabilitySchedule>>({
    name: 'Availability Schedule',
    dataType: 'map',
    Field: MenuAvailabilityField,
    Preview: AvailabilityDays,
  }),
  categoriesPaths: buildProperty<Array<string>>({
    name: 'Categories',
    dataType: 'array',
    of: {
      dataType: 'string',
    },
    validation: { required: true },
    Field: CategoriesPathFields,
    Preview: CategoriesPathPreView,
  }),
  cookingTimeInMinutes: buildProperty<Record<string, unknown>>({
    name: 'Cooking Time (Minutes)',
    validation: { required: true },
    dataType: 'map',
    properties: {
      min: {
        name: 'Min',
        dataType: 'number',
        validation: {
          required: true,
          min: 0,
        },
      },
      max: {
        name: 'Max',
        dataType: 'number',
        validation: {
          required: true,
          min: 0,
        },
      },
    },
  }),
};
