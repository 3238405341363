import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  ListItemText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { FieldProps, PropertyPreviewProps } from 'firecms';
import React, { useCallback, useEffect, useState } from 'react';
import { ServiceFeesComputationVersion } from '../../models/place';
import { CHIP_COLORS } from '../../utils/pick_up_a_color';
import {
  getServiceFeesComputationVersionDescription,
  getServiceFeesComputationVersionTitle,
} from '../../utils/service_fees/service_fees_utils';

interface VersionAugmented {
  id: string;
  value: string;
  label: string;
  isSelected: boolean;
}

const colorKey = Object.keys(CHIP_COLORS);
const color = Math.floor(Math.random() * colorKey.length);
const usedColorScheme = CHIP_COLORS[colorKey[color]];

export const ServiceFeesComputationVersionFields = ({
  value,
  setValue,
  showError,
  error,
}: FieldProps<string>) => {
  const [versionsAugmented, setVersionsAugmented] = useState<
    VersionAugmented[]
  >([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const version = versionsAugmented.find((val) => val.value === value);
    if (!version) {
      return;
    }
    versionsAugmented[versionsAugmented.indexOf(version)].isSelected =
      !version.isSelected;
    setVersionsAugmented([...versionsAugmented]);
    if (version.isSelected) {
      setValue(value);
    }
  };

  const fetchAllVersions = useCallback(async () => {
    const versions: VersionAugmented[] = Object.entries(
      ServiceFeesComputationVersion
    ).map(([key, version]) => {
      return {
        id: key,
        label: getServiceFeesComputationVersionDescription(version),
        isSelected: value === version,
        value: version,
      };
    });
    setVersionsAugmented(versions);
  }, [value]);

  useEffect(() => {
    fetchAllVersions();
  }, [fetchAllVersions]);

  return (
    <FormControl sx={{ m: 1, width: '100%' }}>
      <FormLabel id="demo-radio-buttons-group-label">
        Service Fees Computation Version
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {versionsAugmented &&
          versionsAugmented.map((versionAugmented) => {
            return (
              <FormControlLabel
                key={versionAugmented.id}
                value={versionAugmented.value}
                control={<Radio />}
                label={
                  <Box
                    sx={{
                      with: '100%',
                      border: 2,
                      borderColor: versionAugmented.isSelected
                        ? CHIP_COLORS.blueDark.color
                        : CHIP_COLORS.grayLighter.color,
                      borderRadius: 3,
                      marginTop: 1,
                      marginBottom: 1,
                      padding: 1,
                    }}
                  >
                    <ListItemText
                      primary={
                        <Chip
                          key={versionAugmented.value}
                          sx={{
                            backgroundColor: usedColorScheme.color,
                            color: usedColorScheme.text,
                            marginBottom: 1,
                          }}
                          size={'small'}
                          label={getServiceFeesComputationVersionTitle(
                            versionAugmented.value
                          )}
                        />
                      }
                      secondary={
                        <>
                          {getServiceFeesComputationVersionDescription(
                            versionAugmented.value
                          )}
                        </>
                      }
                      secondaryTypographyProps={{
                        style: { whiteSpace: 'pre-line' },
                      }}
                    />
                  </Box>
                }
              />
            );
          })}
      </RadioGroup>
      {showError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export function ServiceFeesComputationVersionPreview({
  value,
}: PropertyPreviewProps<string>) {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(0.5),
        minWidth: 300,
        maxWidth: 500,
      })}
    >
      <Chip
        key={value}
        sx={{
          backgroundColor: usedColorScheme.color,
          color: usedColorScheme.text,
        }}
        size={'small'}
        label={getServiceFeesComputationVersionTitle(value)}
      />
    </Box>
  );
}
