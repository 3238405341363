export enum Weekday {
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
  sunday = 'Sunday',
}

/// Returns the name of the week day.
export const getNameOfDay = (weekday: number): Weekday => {
  switch (weekday) {
    case 1:
      return Weekday.monday;
    case 2:
      return Weekday.tuesday;
    case 3:
      return Weekday.wednesday;
    case 4:
      return Weekday.thursday;
    case 5:
      return Weekday.friday;
    case 6:
      return Weekday.saturday;
    case 7:
      return Weekday.sunday;
    default:
      return Weekday.monday;
  }
};

/// Returns the index of the week day.
export const getIndexOfDay = (weekdayName: string): number => {
  switch (weekdayName) {
    case Weekday.monday:
      return 1;
    case Weekday.tuesday:
      return 2;
    case Weekday.wednesday:
      return 3;
    case Weekday.thursday:
      return 4;
    case Weekday.friday:
      return 5;
    case Weekday.saturday:
      return 6;
    case Weekday.sunday:
      return 7;
    default:
      return 1;
  }
};
