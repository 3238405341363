import {buildProperty} from 'firecms';

export const referralCodeSchema = {
  code: buildProperty<string>({
    name: 'Code',
    description: 'The referral code',
    dataType: 'string',
    validation: {
      required: true,
      trim: true,
      uppercase: true,
      unique: true,
      min: 4,
    },
  }),
  autogenerated: buildProperty<boolean>({
    name: 'Autogenerated',
    description: 'Whether the referral code was autogenerated',
    dataType: 'boolean',
    readOnly: true,
    disabled: true,
    defaultValue: false,
  }),
};
