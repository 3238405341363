import { buildPropertiesOrBuilder, buildProperty } from 'firecms';
import { Promotion } from '../models/promotion';
import {
  promotionAppliesOn,
  promotionAppliesOnOrderType,
} from '../utils/constants';

export const promotionSchema = buildPropertiesOrBuilder<Partial<Promotion>>({
  promoCode: buildProperty<string>({
    name: 'Promo Code',
    dataType: 'string',
    // Promo codes should be unique non-empty strings with no trailing spaces.
    validation: {
      required: true,
      trim: true,
      uppercase: true,
      unique: true,
      min: 1,
    },
  }),
  isActive: buildProperty<boolean>({
    name: 'Active',
    dataType: 'boolean',
    defaultValue: false,
    validation: { required: true },
  }),
  isAutomaticallyApplied: buildProperty<boolean>({
    name: 'Automatically Applied',
    dataType: 'boolean',
    defaultValue: false,
    validation: { required: false },
  }),
  discountType: buildProperty<string>({
    name: 'Discount Type',
    dataType: 'string',
    validation: { required: true },
    enumValues: {
      PERCENTAGE: 'PERCENTAGE',
      VALUE_IN_XAF: 'VALUE_IN_XAF',
    },
  }),
  appliesOn: buildProperty<string>({
    name: 'Applies On',
    dataType: 'string',
    validation: { required: true },
    enumValues: promotionAppliesOn,
  }),
  appliesOnOrderType: buildProperty<string>({
    name: 'Order Type',
    dataType: 'string',
    validation: { required: false },
    enumValues: promotionAppliesOnOrderType,
  }),
  imputableTo: buildProperty<string>({
    name: 'Imputable To',
    dataType: 'string',
    hideFromCollection: true,
    validation: { required: true },
    defaultValue: 'platform',
    enumValues: {
      platform: 'Platform',
    },
  }),
  discount: buildProperty<number>({
    name: 'Discount Value',
    dataType: 'number',
    validation: { required: true, moreThan: 0 },
  }),
  maxUsageCount: buildProperty<number>({
    name: 'Max Usage Count',
    dataType: 'number',
    validation: { required: false, moreThan: 0, integer: true },
  }),
  minAmountToSpend: buildProperty<number>({
    name: 'Minimum Total Items Price',
    dataType: 'number',
    validation: { required: false, moreThan: 0 },
  }),
});
