import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  FieldProps,
  PropertyPreviewProps,
  useDataSource,
  useSnackbarController,
} from 'firecms';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { membershipPlanCollection } from '../collections/membership_plan_collection';
import { placeCollection } from '../collections/places_collection';
import { subscriptionCollection } from '../collections/subscriptions';
import {
  MembershipPlan,
  MembershipPlanDuration,
} from '../models/membership_plan';
import { Place } from '../models/place';
import { Subscription, SubscriptionStatus } from '../models/subscription';
import { CollectionsNames } from '../utils/database/CollectionsNames';
import { getDatabasePath, getDocumentId } from '../utils/database/path_utils';

type MembershipPlanDoc = { path: string; data: MembershipPlan };
type SubscriptionDoc = { path: string; values: Subscription; id: string };

interface Column {
  id: 'status' | 'priceInXAF' | 'startDateInUtc' | 'enDateInUtc' | 'remove';
  label: string;
}

const columns: readonly Column[] = [
  { id: 'remove', label: '' },
  { id: 'status', label: 'Status' },
  { id: 'priceInXAF', label: 'Price In XAF' },
  { id: 'startDateInUtc', label: 'Start date' },
  { id: 'enDateInUtc', label: 'End date' },
];

export function CustomSubscriptionPathField({
  context,
  value,
  error,
  showError,
  setValue,
}: FieldProps<string, string>) {
  const databaseSource = useDataSource();
  const snackbarController = useSnackbarController();
  const placeId = context.entityId;

  const [activeSubscription, setActiveSubscription] = useState<Subscription>();
  const [nextSubscription, setNextSubscription] = useState<SubscriptionDoc>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [membershipPlans, setMembershipPlans] = useState<MembershipPlanDoc[]>(
    []
  );
  const [selectMembershipPlan, setSelectMembershipPlan] =
    useState<MembershipPlanDoc>();
  const [startDate, setStartDate] = useState<string>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const fetchMembershipPlan = useCallback(async () => {
    const listOfMembershipPlans = await databaseSource.fetchCollection({
      collection: membershipPlanCollection,
      path: 'membershipPlans',
    });
    const activeSubscription =
      value &&
      (await databaseSource.fetchEntity<Subscription>({
        collection: subscriptionCollection,
        path: CollectionsNames.subscriptions,
        entityId: getDocumentId(value),
      }));

    if (activeSubscription) {
      setActiveSubscription(activeSubscription.values);
      setStartDate(activeSubscription.values.endDateInUtc);
    } else {
      setStartDate(new Date().toISOString());
    }

    const enumMembershipRecord: MembershipPlanDoc[] = listOfMembershipPlans.map(
      (item) => {
        return {
          path: getDatabasePath(item.path, item.id),
          data: item.values,
        };
      }
    ) as MembershipPlanDoc[];
    setMembershipPlans(enumMembershipRecord);
  }, [databaseSource, value]);

  const calculateEndDateInUTC = (
    membershipPlan: MembershipPlan,
    date: Date
  ): string => {
    const duration = membershipPlan.duration;

    return duration === MembershipPlanDuration.month
      ? new Date(date.setMonth(date.getMonth() + 1)).toISOString()
      : new Date(date.setFullYear(date.getFullYear() + 1)).toISOString();
  };

  const calculatePriceInXaf = (membershipPlan: MembershipPlan): number => {
    const pricePerUnit = membershipPlan.priceInXAF;
    return membershipPlan.duration === MembershipPlanDuration.month
      ? pricePerUnit
      : pricePerUnit * 12;
  };

  const onDataSubmitting = async () => {
    if (!selectMembershipPlan) {
      setErrorMessage(
        'Failed to create the subscription with Undefined MembershipPlan.'
      );
      return;
    }

    if (!startDate) {
      setErrorMessage('Can create a subscription with empty start date.');
      return;
    }

    if (!placeId) {
      return;
    }
    const now = new Date();
    const sDate = new Date(startDate);
    sDate.setHours(1, 0, 0);
    const startDateInUtc = sDate.toISOString();
    const endDateInUtc = calculateEndDateInUTC(
      selectMembershipPlan.data,
      sDate
    );
    const status =
      now.getTime() > new Date(startDateInUtc).getTime()
        ? SubscriptionStatus.ACTIVATED
        : SubscriptionStatus.PENDING;

    const membershipPlan = selectMembershipPlan.data;
    const subscriberPath = getDatabasePath(CollectionsNames.places, placeId);
    const membershipPlanPath = selectMembershipPlan.path;
    const priceInXAF = calculatePriceInXaf(selectMembershipPlan.data);

    const subscription = {
      membershipPlanPath: membershipPlanPath,
      membershipPlan: membershipPlan,
      endDateInUtc: endDateInUtc,
      startDateInUtc: startDateInUtc,
      priceInXAF: priceInXAF,
      status: status,
      subscriberPath: subscriberPath,
      deposits: null,
    };

    // Creates a new subscription.
    const result = await databaseSource.saveEntity<Subscription>({
      path: CollectionsNames.subscriptions,
      values: subscription,
      collection: subscriptionCollection,
      status: 'new',
    });

    if (result.id) {
      // If the restaurant already has a subscription, the update will be place
      // inside the `nextSubscriptionPath` event if the subscription isn't active.
      if (
        activeSubscription &&
        activeSubscription.status !== SubscriptionStatus.EXPIRED
      ) {
        setNextSubscription(result);
        setOpenConfirmDialog(true);
        return;
      }

      setValue(getDatabasePath(result.path, result.id));
      snackbarController.open({
        type: 'success',
        message: 'Subscription successfully save.',
      });
      return;
    }
  };

  const saveNextSubscriptionPath = async () => {
    if (nextSubscription) {
      const result = await databaseSource.saveEntity<Place>({
        status: 'existing',
        collection: placeCollection,
        path: CollectionsNames.places,
        entityId: placeId,
        values: {
          nextSubscriptionPath: getDatabasePath(
            nextSubscription.path,
            nextSubscription.id
          ),
        },
      });

      result &&
        snackbarController.open({
          type: 'success',
          message: 'Next Subscription Path saved successfully',
        });
    }
    setOpenConfirmDialog(false);
  };

  useEffect(() => {
    fetchMembershipPlan();
  }, [fetchMembershipPlan]);

  return (
    <Stack spacing={1}>
      <FormControl error={!!error}>
        <Box>
          <MembershipPlanSelection
            setCurrentMembershipPlan={setSelectMembershipPlan}
            membershipPlans={membershipPlans}
            selectMembershipPlan={selectMembershipPlan}
          />
          <Box sx={{ height: '20px' }} />
          <StartDateWidget
            setStartDate={setStartDate}
            startDate={startDate}
            currentMembershipPlan={selectMembershipPlan}
            activeEndDate={activeSubscription?.endDateInUtc}
          />
          <Box sx={{ height: '20px' }} />
          <Button color={'info'} variant="contained" onClick={onDataSubmitting}>
            Save Data
          </Button>
        </Box>
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        {showError && <FormHelperText>{error}</FormHelperText>}
        <Dialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This place already contains a subscription. Would you want to
              schedule the next subscription?
              <br />
              NB: By agree this action, this subscription will take effect after
              the active subscription has expired.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)}>
              Disagree
            </Button>
            <Button onClick={saveNextSubscriptionPath} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </FormControl>
    </Stack>
  );
}

const StartDateWidget = (props: {
  currentMembershipPlan?: MembershipPlanDoc;
  startDate?: string;
  setStartDate: (value: string) => void;
  activeEndDate?: string;
}) => {
  if (props.currentMembershipPlan == null) {
    return <Box />;
  }

  const minDate = props.activeEndDate
    ? new Date(props.activeEndDate)
    : new Date();

  return (
    <FormControl
      fullWidth
      required={true}
      sx={{
        '& .MuiInputLabel-root': {
          mt: 1 / 2,
          ml: 1 / 2,
        },
        '& .MuiInputLabel-shrink': {
          mt: 2,
        },
      }}
    >
      <Box>
        <Typography>{'Select start date'}</Typography>
        <Box sx={{ height: '3px' }} />
        <DatePicker
          value={props.startDate}
          minDate={minDate}
          onChange={(newValue) => props.setStartDate(newValue as any)}
          renderInput={(value) => <TextField {...value} />}
          inputFormat="dd.MMMM.yyyy"
        />
      </Box>
    </FormControl>
  );
};

const MembershipPlanSelection = (props: {
  setCurrentMembershipPlan: (value: MembershipPlanDoc) => void;
  membershipPlans: MembershipPlanDoc[];
  selectMembershipPlan?: MembershipPlanDoc;
}) => {
  const changeMembershipPlan = (item: any): void => {
    props.setCurrentMembershipPlan(item.target.value);
  };
  return (
    <FormControl
      fullWidth
      required={true}
      sx={{
        '& .MuiInputLabel-root': {
          mt: 1 / 2,
          ml: 1 / 2,
        },
        '& .MuiInputLabel-shrink': {
          mt: 2,
        },
      }}
    >
      <Box sx={{ marginBottom: '5px' }}>
        <Typography>Select a membershipPlan</Typography>
      </Box>
      <Select
        sx={{
          minHeight: '64px',
        }}
        variant={'filled'}
        value={props.selectMembershipPlan}
        onChange={changeMembershipPlan}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            <Chip
              key={props.selectMembershipPlan?.path}
              label={selected.path}
            />
          </Box>
        )}
      >
        {props.membershipPlans &&
          props.membershipPlans.map((item) => {
            const data = item.data;
            return (
              <MenuItem key={`select_addon_${item.path}`} value={item as any}>
                <Box>
                  <Stack direction={'row'}>
                    <Stack>
                      <Typography>{'Types: '}</Typography>
                    </Stack>
                    <Stack sx={{ minWidth: 0 }}>
                      <Typography noWrap sx={{ color: 'green' }}>
                        {data.type}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Box>
                    <Typography>
                      {'Price: '}
                      {data.priceInXAF}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {'Duration: '}
                      {data.duration}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{'Features: '}</Typography>
                    <Box sx={{ ml: 3 }}>
                      {data.features.map((feature) => (
                        <Box key={feature}>{feature}</Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export function PreviewSubscriptionPathField({
  value,
}: PropertyPreviewProps<Partial<string>>) {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const snackbarController = useSnackbarController();
  const databaseSource = useDataSource();
  const [currentPlaceSubscription, setCurrentPlaceSubscription] =
    useState<Subscription>();
  const currentValue = value as string | undefined | null;
  const [open, setOpen] = useState(false);

  const fetchCurrentPlaceSubscription = useCallback(async () => {
    if (!currentValue) {
      return;
    }
    const placeSubscription = await databaseSource.fetchEntity<Subscription>({
      collection: subscriptionCollection,
      path: CollectionsNames.subscriptions,
      entityId: getDocumentId(currentValue),
    });
    setCurrentPlaceSubscription(placeSubscription?.values);
  }, [databaseSource, currentValue]);

  useEffect(() => {
    if (currentValue) {
      fetchCurrentPlaceSubscription();
      return;
    }
  }, [fetchCurrentPlaceSubscription, currentValue]);

  if (!currentValue || !currentPlaceSubscription) {
    return <Box />;
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeSubscription = async () => {
    const currentPlace = await databaseSource.fetchEntity<Place>({
      collection: placeCollection,
      path: CollectionsNames.places,
      entityId: getDocumentId(currentPlaceSubscription.subscriberPath),
    });
    if (!currentPlace) {
      return;
    }
    const nextSubscriptionPath = currentPlace.values.nextSubscriptionPath;
    const currentSubscriptionPath = currentPlace.values.subscriptionPath;
    if (
      currentPlace.values.nextSubscriptionPath != null &&
      currentPlace.values.nextSubscriptionPath.length === 0
    ) {
      snackbarController.open({
        type: 'success',
        message: 'The next subscription path will be removed',
      });
    }
    /// Cancel the current subscription.
    if (currentSubscriptionPath) {
      await databaseSource.saveEntity<Subscription>({
        collection: placeCollection,
        path: CollectionsNames.subscriptions,
        status: 'existing',
        entityId: getDocumentId(currentSubscriptionPath),
        values: {
          status: SubscriptionStatus.CANCELED_BY_SPREELOOP,
        },
      });
    }

    /// Cancels the next subscription.
    if (nextSubscriptionPath) {
      await databaseSource.saveEntity<Subscription>({
        collection: placeCollection,
        path: CollectionsNames.subscriptions,
        status: 'existing',
        entityId: getDocumentId(nextSubscriptionPath),
        values: {
          status: SubscriptionStatus.CANCELED_BY_SPREELOOP,
        },
      });
    }
    await databaseSource.saveEntity<Place>({
      collection: placeCollection,
      path: CollectionsNames.places,
      status: 'existing',
      entityId: currentPlace.id,
      values: {
        subscriptionPath: null,
        nextSubscriptionPath: null,
      },
    });

    return;
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(0.5),
      })}
    >
      <Button variant="text" onClick={handleClickOpen}>
        {value}
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={'center'}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Button
                      color={'error'}
                      variant="contained"
                      startIcon={<DeleteIcon />}
                      onClick={() => setOpenConfirmDialog(true)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    {currentPlaceSubscription.status}
                  </TableCell>
                  <TableCell align="center">
                    {currentPlaceSubscription.priceInXAF}
                  </TableCell>
                  <TableCell align="center">
                    {moment(currentPlaceSubscription.startDateInUtc).format(
                      'MMMM Do YYYY'
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {moment(currentPlaceSubscription.endDateInUtc).format(
                      'MMMM Do YYYY'
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By delete this subscription, we also delete the next schedule
            subscription if present.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Disagree</Button>
          <Button onClick={removeSubscription} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
