export interface MembershipPlan {
  features: MembershipPlanFeature[];
  type: MembershipPlanType;
  priceInXAF: number;
  duration: MembershipPlanDuration;
  appliesTo: MembershipPlanAppliesTo;
}

export enum MembershipPlanFeature {
  partnerCanPrintOrders = 'PARTNER_CAN_PRINT_ORDERS',
  partnerCanGenerateOrdersReceipt = 'PARTNER_CAN_GENERATE_ORDERS_RECEIPTS',
  partnerCanEditOrdersAfterCreation = 'PARTNER_CAN_EDIT_ORDERS_AFTER_CREATION',
  partnerCanCreateOrderWithPosUi = 'PARTNER_CAN_CREATE_ORDER_WITH_POS_UI',
  partnerCanTrackPartnerSubAccountsStatistics = 'PARTNER_CAN_TRACK_SUB_ACCOUNTS_STATISTICS',
  partnerCanUseTheQRCode = 'PARTNER_CAN_USE_THE_QR_CODE',
}

export enum MembershipPlanType {
  premium = 'PREMIUM',
  basic = 'BASIC',
  pro = 'PRO',
}

export enum MembershipPlanAppliesTo {
  partner = 'PARTNER',
}

export enum MembershipPlanDuration {
  month = 'MONTH',
  year = 'YEAR',
}
