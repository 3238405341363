export function formatUserNameAbbreviateLastName(firstName: string, lastName: string): string {
  if (firstName.length >= 2 && lastName.length >= 2) {
    return `${capitalize(firstName)} ${lastName[0].toUpperCase()}.`;
  }

  return 'Anonymous';
}

function capitalize(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}
