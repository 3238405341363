import { buildProperty } from 'firecms';
import { PartnerPlacesPreview } from '../widgets/partner_preview';

enum PartnerValidationStatus {
  pending = 'pending',
  suspended = 'suspended',
  approved = 'approved',
}

export const partnerSchema = {
  placePath: buildProperty<string>({
    readOnly: true,
    name: 'Selected Place',
    description: 'Path to the selected place',
    dataType: 'string',
    validation: { required: true, trim: true },
  }),
  activePlaceBrandName: buildProperty<string>({
    readOnly: true,
    name: 'Active Place Brand Name',
    description: 'The name of active place brand name',
    dataType: 'string',
    validation: { required: true, trim: true },
  }),
  validationStatus: buildProperty<string>({
    name: 'Validation Status',
    dataType: 'string',
    enumValues: PartnerValidationStatus,
    validation: { required: true },
  }),
  firstName: buildProperty<string>({
    readOnly: true,
    name: 'First Name',
    description: 'The sub-account user first name',
    dataType: 'string',
  }),
  lastName: buildProperty<string>({
    readOnly: true,
    name: 'Last Name',
    description: 'The sub-account user last name',
    dataType: 'string',
  }),
  phoneNumber: buildProperty<string>({
    readOnly: true,
    name: 'Phone Number',
    description: 'The sub-account phone number',
    dataType: 'string',
  }),
  canEditPermissionsForAllPlaces: buildProperty<boolean>({
    name: 'Can Edit Permissions For All Places',
    description:
      'The Permission that allows a partner to edit permissions of other partners for all places',
    dataType: 'boolean',
  }),
  places: buildProperty<Record<string, unknown>>({
    name: 'Associated Places',
    description: 'The places associated with the sub-account',
    validation: { required: false },
    dataType: 'map',
    keyValue: true,
    Preview: PartnerPlacesPreview,
  }),
};
