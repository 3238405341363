import { buildCollection, EntityOnFetchProps } from 'firecms';
import moment from 'moment';
import { CourierReview } from '../models/courier_review';
import { isSpreeloopDeveloper } from '../utils/users_verification';
import { courierReviewSchema } from '../schemas/courier_review_schema';

export const courierReviewCollection = buildCollection({
  path: 'couriersReviews',
  properties: courierReviewSchema,
  name: 'Couriers Reviews',
  callbacks: {
    onFetch({ entity }: EntityOnFetchProps<CourierReview>) {
      const createdAt = entity.values.createdAt;
      if (createdAt !== undefined) {
        entity.values.createdAt = moment(createdAt).format(
          'MMMM Do YYYY, h:mm:ss a'
        );
      }
      return entity;
    },
  },
  permissions: ({ authController }) => ({
    edit: isSpreeloopDeveloper(authController.user),
    create: false,
    delete: false,
  }),
});
