import { EnumValueConfig, buildProperty } from 'firecms';
import {
  MembershipPlanAppliesTo,
  MembershipPlanDuration,
  MembershipPlanFeature,
  MembershipPlanType,
} from '../models/membership_plan';

const membershipPlanFeature: EnumValueConfig[] = Object.entries(
  MembershipPlanFeature
).map(([key, value]) => {
  const id = value;
  const label = key;
  return { id, label };
});
const membershipPlanType: EnumValueConfig[] = Object.entries(
  MembershipPlanType
).map(([key, value]) => {
  const id = value;
  const label = key;
  return { id, label };
});

const duration: EnumValueConfig[] = Object.entries(MembershipPlanDuration).map(
  ([key, value]) => {
    const id = value;
    const label = key;
    return { id, label };
  }
);
const appliesTo: EnumValueConfig[] = Object.entries(
  MembershipPlanAppliesTo
).map(([key, value]) => {
  const id = value;
  const label = key;
  return { id, label };
});

export const membershipPlanSchema = {
  features: buildProperty({
    name: 'Features',
    dataType: 'array',
    validation: { required: true },
    of: {
      dataType: 'string',
      enumValues: membershipPlanFeature,
    },
  }),
  type: buildProperty<string>({
    name: 'Type',
    dataType: 'string',
    validation: { required: true },
    enumValues: membershipPlanType,
  }),
  priceInXAF: buildProperty<number>({
    name: 'Price In XAF',
    dataType: 'number',
    validation: { required: true },
  }),
  duration: buildProperty<string>({
    name: 'Duration',
    dataType: 'string',
    validation: { required: true },
    enumValues: duration,
  }),
  appliesTo: buildProperty<string>({
    name: 'Applies To',
    dataType: 'string',
    validation: { required: true },
    enumValues: appliesTo,
  }),
};
