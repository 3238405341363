import '@fontsource/ibm-plex-mono';
import 'typeface-rubik';

import { User as FirebaseUser, ProviderId } from 'firebase/auth';
import { AppCheckOptions, Authenticator, FirebaseCMSApp } from 'firecms';
import { achievementsRewardsOverridesCollection } from './collections/achievements_rewards_overrides_collection';
import { carouselItemsCollection } from './collections/carousel_items_collection';
import { categoryCollection } from './collections/categories_collection';
import { courierCollection } from './collections/couriers_collection';
import { courierReviewCollection } from './collections/couriers_reviews_collection';
import { mapZoneCollection } from './collections/map_zone_collection';
import { membershipPlanCollection } from './collections/membership_plan_collection';
import { orderReviewCollection } from './collections/orders_reviews_collection';
import { partnerCollection } from './collections/partners_collection';
import { placeCollection } from './collections/places_collection';
import { promotionCollection } from './collections/promotions_collection';
import { referralCodesCollection } from './collections/referral_codes_collection';
import { subscriptionCollection } from './collections/subscriptions';
import { withdrawalTransactionsAuditsCollection } from './collections/withdrawal_transactions_audits_collection';
import logo from './images/logo512.png';
import { isSpreeloopUser } from './utils/users_verification';

export default function App() {
  const listOfCollections = [
    categoryCollection,
    placeCollection,
    promotionCollection,
    courierCollection,
    mapZoneCollection,
    partnerCollection,
    orderReviewCollection,
    courierReviewCollection,
    referralCodesCollection,
    achievementsRewardsOverridesCollection,
    withdrawalTransactionsAuditsCollection,
    carouselItemsCollection,
    membershipPlanCollection,
    subscriptionCollection,
  ];

  const authenticator: Authenticator<FirebaseUser> = async ({ user }) => {
    // Allow @spreeloop.com users only.
    // Eventually we will implement a more granular permission model.
    // For example, DEVs and On-Callers should have more permissions than
    // agents that collect data on the ground.
    if (isSpreeloopUser(user)) {
      return true;
    }

    throw Error('Only @spreeloop.com users allowed!');
  };

  // Use the appropriate key for each environment.
  const webRecaptchaSiteKey = process.env.REACT_APP_WEB_RECAPTCHA_SITE_KEY;
  const appCheckOptions: AppCheckOptions | undefined = webRecaptchaSiteKey
    ? {
        providerKey: webRecaptchaSiteKey,
        useEnterpriseRecaptcha: false,
        isTokenAutoRefreshEnabled: true,
      }
    : undefined;

  return (
    <FirebaseCMSApp
      name={'Spreeloop Place CMS'}
      logo={logo}
      authentication={authenticator}
      signInOptions={[ProviderId.GOOGLE]}
      collections={listOfCollections}
      appCheckOptions={appCheckOptions}
    />
  );
}
