import { buildProperty } from 'firecms';

enum ReviewStatus {
  active = 'ACTIVE',
  suspended = 'SUSPENDED',
  unknown = 'UNKNOWN',
  pending = 'PENDING',
  done = 'DONE',
}

export const courierReviewSchema = {
    politeness: buildProperty<number>({
    readOnly: true,
    name: 'Politeness',
    dataType: 'number',
    validation: { required: false },
  }),
  packagingState: buildProperty<number>({
    readOnly: true,
    name: 'Packaging',
    dataType: 'number',
    validation: { required: false },
  }),
  review: buildProperty<string>({
    readOnly: true,
    name: 'Message',
    dataType: 'string',
    validation: { required: false },
  }),
  userPath: buildProperty<string>({
    readOnly: true,
    name: 'User Path',
    dataType: 'string',
    validation: { required: false },
  }),
  status: buildProperty<string>({
    name: 'Status',
    dataType: 'string',
    enumValues: ReviewStatus,
    validation: { required: false },
  }),
  courierPath: buildProperty<string>({
    readOnly: true,
    name: 'Courier Path',
    dataType: 'string',
    validation: { required: true },
  }),
  createdAt: buildProperty<string>({
    readOnly: true,
    name: 'Creation Date ISO Format',
    description: 'e.g: 2021-12-27T20:50:58.116137Z',
    dataType: 'string',
    validation: { required: false },
  }),
};
