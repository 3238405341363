import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  alpha,
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Stack,
  styled,
  Switch,
  TextField,
} from '@mui/material';
import { pink } from '@mui/material/colors';
import {
  Entity,
  EnumValueConfig,
  EnumValuesChip,
  FieldProps,
  getIconForProperty,
  LabelWithIcon,
  SaveEntityProps,
  useDataSource,
  useSnackbarController,
} from 'firecms';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  addOnItemCollection,
  placeItemAddOnCollection,
} from '../collections/places_collection';
import { AddOnItem, PlaceItemAddOn } from '../models/place';
import { getOldestParentDocumentPath } from '../utils/database/path_utils';

export default function CustomListOfAddOnItem({
  property,
  value,
  setValue,
  error,
  isSubmitting,
  context,
  showError,

  ...props
}: FieldProps<string, string>) {
  const databaseSource = useDataSource();
  const [listOfMenuAddOnItem, setListOfMenuAddOnItem] =
    useState<Entity<PlaceItemAddOn>[]>();

  const [listOfOldAddOnItem, setListOfOldAddOnItem] =
    useState<Entity<AddOnItem>[]>();

  const [displayPlaceItemAddOnParams, setDisplayPlaceItemAddOnParams] =
    useState<boolean>(false);

  const [enumAddOn, setEnumAddOn] = useState<EnumValueConfig[]>([]);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const placePath = getOldestParentDocumentPath(context.path) ?? '';

  const fetchOldAddOn = useCallback(async () => {
    const listOfOldAddOnItem = await databaseSource.fetchCollection({
      collection: addOnItemCollection,
      path: context.path,
    });

    setListOfOldAddOnItem(listOfOldAddOnItem);
  }, [context.path, databaseSource]);

  const checkValidityOfSelectedAddOn = useCallback(
    (value: string): boolean => {
      const valueFound = listOfOldAddOnItem?.find(
        (item) =>
          item.values.placeItemAddOnId === value ||
          item.values.menuItemAddOnId === value
      );
      if (!valueFound) {
        return false;
      }
      return true;
    },
    [listOfOldAddOnItem]
  );

  const fetchMenuAddOnItem = useCallback(async () => {
    const listMenuAddOnItem = await databaseSource.fetchCollection({
      collection: placeItemAddOnCollection,
      path: `${placePath}/menuItemAddOns`,
    });

    const enumAddOnRecord: EnumValueConfig[] =
      listMenuAddOnItem.map<EnumValueConfig>((item) => {
        return { id: item.id, label: item.values.itemName };
      });
    setListOfMenuAddOnItem(listMenuAddOnItem);
    setEnumAddOn(enumAddOnRecord);
  }, [databaseSource, placePath]);

  const isSubmite = useMemo(() => isSubmitting, [isSubmitting]);

  useEffect(() => {
    fetchMenuAddOnItem();
  }, [fetchMenuAddOnItem]);

  useEffect(() => {
    fetchOldAddOn();
  }, [fetchOldAddOn]);

  useEffect(() => {
    if (isSubmite) {
      context.values.placeItemAddOnId = context.values.addOnItem;
    }
  }, [context.values, isSubmite]);

  const changeCurrentAddon = (item: any): void => {
    setValue(item.target.value);
    if (displayPlaceItemAddOnParams) {
      setDisplayPlaceItemAddOnParams(false);
    }
    if (checkValidityOfSelectedAddOn(item.target.value)) {
      setErrorMessage('This value already exists and should be unique');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <Stack spacing={1}>
      <FormControl
        fullWidth
        required={property.validation?.required}
        error={!!error}
        disabled={isSubmitting}
        sx={{
          '& .MuiInputLabel-root': {
            mt: 1 / 2,
            ml: 1 / 2,
          },
          '& .MuiInputLabel-shrink': {
            mt: 2,
          },
        }}
      >
        <InputLabel id={`label-select-label`}>
          <LabelWithIcon
            icon={getIconForProperty(property)}
            title="Select An AddOn"
          />
        </InputLabel>

        <MuiSelect
          sx={{
            minHeight: '64px',
          }}
          variant={'filled'}
          value={value ?? ''}
          disabled={isSubmitting}
          onChange={changeCurrentAddon}
        >
          {enumAddOn &&
            enumAddOn.map((enumConfig) => {
              return (
                <MenuItem
                  key={`select_addon_${enumConfig.id}`}
                  value={enumConfig.id}
                >
                  <EnumValuesChip
                    enumKey={enumConfig.id}
                    enumValues={enumAddOn}
                    small={true}
                  />
                </MenuItem>
              );
            })}
        </MuiSelect>

        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        {showError && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
      <FormGroup>
        <FormControlLabel
          label="New Add-on "
          labelPlacement="start"
          checked={props.disabled}
          control={
            <GreenSwitch
              defaultChecked
              checked={displayPlaceItemAddOnParams}
              onChange={() => {
                if (!displayPlaceItemAddOnParams) {
                  setValue('');
                }
                setDisplayPlaceItemAddOnParams(!displayPlaceItemAddOnParams);
              }}
            />
          }
          disabled={isSubmitting}
        />
      </FormGroup>
      {displayPlaceItemAddOnParams ? (
        <NewAddOnComponent
          isSubmitting={isSubmitting}
          placeItemAddOnId={context.values.placeItemAddOnId}
          saveEntity={databaseSource.saveEntity}
          listOfAddOnItem={listOfMenuAddOnItem}
          displayPlaceItemAddOnParams={displayPlaceItemAddOnParams}
          setDisplayPlaceItemAddOnParams={setDisplayPlaceItemAddOnParams}
          enumAddOn={enumAddOn}
          setEnumAddOn={setEnumAddOn}
          placePath={placePath}
        />
      ) : (
        <Box />
      )}
    </Stack>
  );
}

function NewAddOnComponent(props: {
  isSubmitting: boolean | undefined;
  displayPlaceItemAddOnParams: boolean;
  listOfAddOnItem: Entity<PlaceItemAddOn>[] | undefined;
  saveEntity: (
    arg0: SaveEntityProps<PlaceItemAddOn>
  ) => Promise<Entity<PlaceItemAddOn>>;
  placePath: string;
  setDisplayPlaceItemAddOnParams: (arg0: boolean) => void;
  placeItemAddOnId: string;
  setEnumAddOn: (arg0: EnumValueConfig[]) => void;
  enumAddOn: EnumValueConfig[];
}) {
  const snackbarController = useSnackbarController();
  const [globalAvailabityfocus, setFocus] = useState<boolean>(false);
  const [globalAvailabilityValue, setGlobalAvailabilityValue] =
    useState<boolean>(true);
  const [globalAddOnPrice, setGlobalAddOnPrice] = useState<number>(0);
  const [globalAddOnNonDiscountedPrice, setGlobalAddOnNonDiscountedPrice] =
    useState<number>(0);
  const [globalAddOnName, setGlobalAddOnName] = useState<string>();
  const [loading, setLoading] = useState(false);

  const saveNewAddOn = async () => {
    if (props.displayPlaceItemAddOnParams) {
      if (!globalAddOnName) {
        snackbarController.open({
          type: 'error',
          message: 'Name of AddOn is not defined ',
        });
      } else if (
        props.listOfAddOnItem?.find(
          (item: Entity<PlaceItemAddOn>) =>
            item.values.itemName.toUpperCase() ===
            globalAddOnName.trim().toUpperCase()
        )
      ) {
        snackbarController.open({
          type: 'error',
          message: `${globalAddOnName} already exist`,
        });
      } else {
        setLoading(true);
        const addOnSave = await props.saveEntity({
          collection: placeItemAddOnCollection,
          path: `${props.placePath}/menuItemAddOns`,
          values: {
            additionalPriceInXAF: globalAddOnPrice,
            additionalNonDiscountedPriceInXAF: globalAddOnNonDiscountedPrice,
            isAvailable: globalAvailabilityValue,
            itemName: globalAddOnName,
          },
          status: 'new',
        });

        if (addOnSave) {
          snackbarController.open({
            type: 'success',
            message: `New addon save with id ${addOnSave.id}`,
          });
          props.setDisplayPlaceItemAddOnParams(false);
          setGlobalAddOnName('');
          setGlobalAddOnPrice(0);
          setLoading(false);
          props.placeItemAddOnId = addOnSave.id;
          props.setEnumAddOn([
            ...props.enumAddOn,
            {
              id: addOnSave.id,
              label: addOnSave.values.itemName,
            },
          ]);
        } else {
          snackbarController.open({
            type: 'error',
            message: 'Something is happen. please try again.',
          });
          setLoading(false);
        }
      }
    }
  };

  return (
    <Stack
      spacing={5}
      sx={{
        padding: '25px',
        borderWidth: '2px',
        borderColor: '#ffffff57',
        borderStyle: 'solid',
      }}
    >
      <TextField
        sx={{
          minHeight: '64px',
        }}
        required
        id="filled-required"
        label="AddOn Name"
        variant="filled"
        value={globalAddOnName}
        onChange={(item) => {
          setGlobalAddOnName(item.target.value);
        }}
      />
      <FormControl>
        <TextField
          sx={{
            minHeight: '64px',
          }}
          id="filled-number"
          label="Global Price in XAF"
          type="number"
          variant="filled"
          value={globalAddOnPrice}
          onChange={(item) => {
            setGlobalAddOnPrice(+item.target.value);
          }}
          inputProps={{
            min: '0',
          }}
        />
      </FormControl>

      <FormControl>
        <TextField
          sx={{
            minHeight: '64px',
          }}
          id="filled-number"
          label="Global non discounted Price in XAF"
          type="number"
          variant="filled"
          value={globalAddOnNonDiscountedPrice}
          onChange={(item) => {
            setGlobalAddOnNonDiscountedPrice(+item.target.value);
          }}
          inputProps={{
            min: '0',
          }}
        />
      </FormControl>

      <GlobalAvailability
        isSubmitting={props.isSubmitting}
        globalAvailablefocus={globalAvailabityfocus}
        setFocus={setFocus}
        globalAvailableValue={globalAvailabilityValue}
        setGlobalAvailabilityValue={setGlobalAvailabilityValue}
      />
      <Stack direction="row" spacing={2}>
        <LoadingButton
          loading={loading}
          variant="outlined"
          color="primary"
          size="medium"
          startIcon={<AddIcon />}
          onClick={saveNewAddOn}
        >
          Create
        </LoadingButton>
      </Stack>
    </Stack>
  );
}

function GlobalAvailability(props: {
  globalAvailablefocus: any;
  setFocus: (arg0: boolean) => void;
  globalAvailableValue: boolean | undefined;
  setGlobalAvailabilityValue: (arg0: boolean) => void;
  isSubmitting: boolean | undefined;
}) {
  return (
    <FormControl fullWidth>
      <FormControlLabel
        sx={(theme) => ({
          justifyContent: 'space-between',
          margin: 0,
          width: '100%',
          minHeight: '64px',
          paddingLeft: '16px',
          paddingRight: '24px',
          color: 'rgba(0, 0, 0, 0.87)',
          boxSizing: 'border-box',
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
          backgroundColor:
            theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.06)'
              : 'rgba(255, 255, 255, 0.09)',
          borderTopLeftRadius: `${theme.shape.borderRadius}px`,
          borderTopRightRadius: `${theme.shape.borderRadius}px`,
          transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          '&::before': {
            borderBottom: props.globalAvailablefocus
              ? theme.palette.mode === 'light'
                ? '1px solid rgba(255, 255, 255, 0.7)'
                : '1px solid rgba(0, 0, 0, 0.87)'
              : theme.palette.mode === 'light'
              ? '1px solid rgba(0, 0, 0, 0.42)'
              : '1px solid rgba(255, 255, 255, 0.7)',
            left: 0,
            bottom: 0,
            content: '"\\00a0"',
            position: 'absolute',
            right: 0,
            transition:
              'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            pointerEvents: 'none',
          },
          '&::after': {
            content: '""',
            transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            left: 0,
            bottom: 0,
            position: 'absolute',
            right: 0,
            transform: props.globalAvailablefocus ? 'scaleX(1)' : 'scaleX(0)',
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            pointerEvents: props.globalAvailablefocus ? 'none' : undefined,
          },
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.09)'
                : 'rgba(255, 255, 255, 0.13)',
          },
        })}
        onClick={() => props.setFocus(true)}
        labelPlacement={'start'}
        checked={props.globalAvailableValue}
        control={
          <Switch
            checked={props.globalAvailableValue}
            onFocus={() => props.setFocus(true)}
            onBlur={() => props.setFocus(false)}
            onChange={(evt) => {
              props.setFocus(true);
              props.setGlobalAvailabilityValue(evt.target.checked);
            }}
          />
        }
        disabled={props.isSubmitting}
        label="Is globally Available"
      />
    </FormControl>
  );
}

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));
