import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {
  FieldProps,
  LabelWithIcon,
  getIconForProperty,
  useDataSource,
  useSnackbarController,
} from 'firecms';
import { useCallback, useEffect, useState } from 'react';
import { membershipPlanCollection } from '../collections/membership_plan_collection';
import { placeCollection } from '../collections/places_collection';
import { MembershipPlan } from '../models/membership_plan';
import { Place } from '../models/place';
import { CollectionsNames } from '../utils/database/CollectionsNames';
import { getDatabasePath } from '../utils/database/path_utils';
import { CHIP_COLORS } from '../utils/pick_up_a_color';

export default function SubscriptionMembershipPathField({
  property,
  value,
  setValue,
  error,
  isSubmitting,
}: FieldProps<string, string>) {
  const databaseSource = useDataSource();

  const [recordMembershipPlan, setRecordMembershipPlan] = useState<
    { path: string; data: MembershipPlan }[]
  >([]);

  const fetchMembershipPlan = useCallback(async () => {
    const listMembershipPlan = await databaseSource.fetchCollection({
      collection: membershipPlanCollection,
      path: 'membershipPlans',
    });

    const enumMembershipRecord: { path: string; data: MembershipPlan }[] =
      listMembershipPlan.map((item) => {
        return {
          path: getDatabasePath(item.path, item.id),
          data: item.values,
        };
      }) as { path: string; data: MembershipPlan }[];
    setRecordMembershipPlan(enumMembershipRecord);
  }, [databaseSource]);

  useEffect(() => {
    fetchMembershipPlan();
  }, [fetchMembershipPlan]);

  const changeMembershipPlanPath = (item: any): void => {
    setValue(item.target.value);
  };

  return (
    <Stack spacing={1}>
      <FormControl
        fullWidth
        required={property.validation?.required}
        error={!!error}
        disabled={isSubmitting}
        sx={{
          '& .MuiInputLabel-root': {
            mt: 1 / 2,
            ml: 1 / 2,
          },
          '& .MuiInputLabel-shrink': {
            mt: 2,
          },
        }}
      >
        <InputLabel id={`label-select-label`}>
          <LabelWithIcon
            icon={getIconForProperty(property)}
            title="Select An Membership Plan"
          />
        </InputLabel>

        <Select
          sx={{
            minHeight: '64px',
          }}
          variant={'filled'}
          value={value ?? ''}
          disabled={isSubmitting}
          onChange={changeMembershipPlanPath}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              <Chip key={value} label={selected} />
            </Box>
          )}
        >
          {recordMembershipPlan &&
            recordMembershipPlan.map((item) => {
              const colorKey = Object.keys(CHIP_COLORS);
              const color = Math.floor(Math.random() * colorKey.length);
              const usedColorScheme = CHIP_COLORS[colorKey[color]];
              return (
                <MenuItem key={`select_addon_${item.path}`} value={item.path}>
                  <Box>
                    <Chip
                      sx={{
                        backgroundColor: usedColorScheme.color,
                        color: usedColorScheme.text,
                      }}
                      size={'medium'}
                      variant={'filled'}
                      label={item.path}
                    />
                    <Box sx={{ ml: 2 }}>
                      <Typography>
                        {'Duration: '}
                        {item.data.duration}
                      </Typography>
                      <Typography>
                        {'Price: '}
                        {item.data.priceInXAF}
                      </Typography>
                      <Typography>
                        {'Applies To: '}
                        {item.data.appliesTo}
                      </Typography>
                      <Typography>
                        {'Type: '}
                        {item.data.type}
                      </Typography>
                      <Typography>{'Features: '}</Typography>
                      <Box sx={{ ml: 3 }}>
                        {item.data.features.map((feature) => (
                          <Box key={feature}>{feature}</Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Stack>
  );
}
export function SubscriptionSubscriberPathField({
  property,
  value,
  setValue,
  error,
  isSubmitting,
}: FieldProps<string, string>) {
  const databaseSource = useDataSource();
  const snackbarController = useSnackbarController();

  const [recordSubscriber, setRecordSubscriber] = useState<
    { path: string; data: Place }[]
  >([]);

  const fetchSubscriber = useCallback(async () => {
    const listSubscriber = await databaseSource.fetchCollection<Place>({
      collection: placeCollection,
      path: CollectionsNames.places,
    });

    const enumSubscriberRecord: { path: string; data: Place }[] =
      listSubscriber.map((item) => {
        return { path: getDatabasePath(item.path, item.id), data: item.values };
      });
    setRecordSubscriber(enumSubscriberRecord);
  }, [databaseSource]);

  useEffect(() => {
    fetchSubscriber();
  }, [fetchSubscriber]);

  const changeSubscriberPath = async (item: any): Promise<void> => {
    const currentSubscriber = recordSubscriber.find(
      (data) => data.path === value
    );
    if (currentSubscriber && currentSubscriber.data?.subscriptionPath) {
      snackbarController.open({
        type: 'warning',
        message: `This subscription is already linked to a subscriber with path ${value}`,
        autoHideDuration: 6000,
      });
      return;
    }
    const newSubscriber = recordSubscriber.find(
      (data) => data.path === item.target.value
    );
    if (!newSubscriber) {
      return;
    }
    if (!newSubscriber.data?.subscriptionPath) {
      setValue(item.target.value);

      return;
    }
    snackbarController.open({
      type: 'warning',
      message: 'Subscriber already have an subscription',
      autoHideDuration: 6000,
    });
  };

  return (
    <Stack spacing={1}>
      <FormControl
        fullWidth
        required={property.validation?.required}
        error={!!error}
        disabled={isSubmitting}
        sx={{
          '& .MuiInputLabel-root': {
            mt: 1 / 2,
            ml: 1 / 2,
          },
          '& .MuiInputLabel-shrink': {
            mt: 2,
          },
        }}
      >
        <InputLabel id={`label-select-label`}>
          <LabelWithIcon
            icon={getIconForProperty(property)}
            title="Select An Subscriber"
          />
        </InputLabel>

        <Select
          sx={{
            minHeight: '64px',
          }}
          variant={'filled'}
          value={value ?? ''}
          disabled={isSubmitting}
          onChange={changeSubscriberPath}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              <Chip key={value} label={selected} />
            </Box>
          )}
        >
          {recordSubscriber &&
            recordSubscriber.map((item) => {
              const colorKey = Object.keys(CHIP_COLORS);
              const color = Math.floor(Math.random() * colorKey.length);
              const usedColorScheme = CHIP_COLORS[colorKey[color]];
              return (
                <MenuItem key={`select_addon_${item.path}`} value={item.path}>
                  <Box>
                    <Chip
                      sx={{
                        backgroundColor: usedColorScheme.color,
                        color: usedColorScheme.text,
                      }}
                      size={'medium'}
                      variant={'filled'}
                      label={item.path}
                    />
                    <Box sx={{ ml: 2 }}>
                      <Typography>
                        {'Brand Name: '}
                        {item.data.brandName}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Stack>
  );
}
