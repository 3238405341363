import {
  Entity,
  EntityOnFetchProps,
  buildCollection,
} from 'firecms';
import { subscriptionSchema } from '../schemas/subscription_schema';
import moment from 'moment';

export const subscriptionCollection = buildCollection({
  path: 'subscriptions',
  properties: subscriptionSchema,
  name: 'Subscriptions ',
  callbacks: {
    onFetch: async ({ entity }: EntityOnFetchProps) => {
      if (entity.values.startDateInUtc) {
        entity.values.startDate = moment(
          new Date(entity.values.startDateInUtc)
        );
      }
      if (entity.values.endDateInUtc) {
        entity.values.endDate = moment(entity.values.endDateInUtc).format(
          'MMMM Do YYYY'
        );
      }

      if (entity.values.deposits) {
        entity.values.deposits.forEach((deposit: any) => {
          if (deposit.createdAtInUtc) {
            deposit.createdAt = moment(new Date(deposit.createdAtInUtc));
          }
        });
      }

      /// Deletes all the nullable field.
      cleanNullFields(entity);

      return entity;
    },
  },
  permissions: () => ({
    edit: false,
    create: false,
    delete: false,
  }),
});


function cleanNullFields(entity: Entity<any>) {
  Object.keys(entity.values).forEach((key) => {
    if (!entity.values[key]) {
      delete entity.values[key];
    }
  });
}
