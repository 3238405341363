import { ServiceFeesComputationVersion } from '../../models/place';

/**
 * Gets the description of the service fees computation version.
 * @param value - The version.
 * @returns the description of the service fees computation version.
 */
export function getServiceFeesComputationVersionDescription(
  value: string
): string {
  switch (value) {
    case ServiceFeesComputationVersion.version1:
      return 'dine-in : service fees -> 100 XAF.\ntake-away : service fees -> 100 XAF.\ndelivery : service fees -> 5% with minimum 250 XAF maximum 1000 XAF.';
    case ServiceFeesComputationVersion.version2:
      return 'dine-in : service fees -> 0 XAF.\ntake-away : service fees -> 0 XAF.\ndelivery : service fees -> 5% with minimum 250 XAF maximum 1000 XAF.';
    case ServiceFeesComputationVersion.version3:
      return 'dine-in : service fees -> 2000 XAF.\ntake-away : service fees -> 2000 XAF.\ndelivery : service fees -> 2000 XAF.';
  }
  return '';
}

/**
 * Gets the title of the service fees computation version.
 * @param value - The version.
 * @returns the title of the service fees computation version.
 */
export function getServiceFeesComputationVersionTitle(value: string): string {
  switch (value) {
    case ServiceFeesComputationVersion.version1:
      return 'Version 1';
    case ServiceFeesComputationVersion.version2:
      return 'Version 2';
    case ServiceFeesComputationVersion.version3:
      return 'Version 3';
  }
  return '';
}
