import { PropertiesOrBuilders, buildProperty } from 'firecms';
import { Category, InternationalizedString } from '../models/category';

export const CategoriesSchema: PropertiesOrBuilders<Category> = {
  priority: buildProperty<number>({
    name: 'Priority',
    description: 'Descending order of display',
    validation: {
      required: true,
      min: 1,
      max: 999,
      unique: true,
    },
    dataType: 'number',
  }),
  internationalizedTitle: buildProperty<InternationalizedString>({
    name: 'Title',
    validation: { required: true, unique: true },
    dataType: 'map',
    properties: {
      en: buildProperty<string>({
        name: 'English',
        validation: {
          required: true,
        },
        dataType: 'string',
      }),
      fr: buildProperty<string>({
        name: 'French',
        validation: {
          required: true,
        },
        dataType: 'string',
      }),
    },
  }),
};
