import { buildCollection, EntityOnFetchProps } from 'firecms';
import { PlacePaymentGateway } from '../models/place';
import { earningsDepositSchema } from '../schemas/earnings_deposit_schema';
import { placeItemAddOnSchema } from '../schemas/place_item_addon_schema';
import {
  addOnGroupsSchema,
  addOnItemSchema,
  menuItemSchema,
  placeSchema,
} from '../schemas/places_schema';
import { reservationsItemsSchema } from '../schemas/reservations_items_schema';
import { PaymentGateway } from '../utils/constants';
import { CollectionsNames } from '../utils/database/CollectionsNames';
import { checkIfEarningDepositsAccountHasBeenModified } from '../utils/eranings_deposits_account/eranings_deposits_account';
import {
  isPlaceActivationManager,
  isWithdrawalManager,
} from '../utils/users_verification';

export const addOnItemCollection = buildCollection({
  path: 'addOnItems',
  name: 'Add-On Items',
  properties: addOnItemSchema,

  callbacks: {
    onFetch: async ({ context, entity, path }: EntityOnFetchProps) => {
      entity.values.placeItemAddOnId =
        entity.values.placeItemAddOnId || entity.values.menuItemAddOnId;
      const addOnId = entity.values.placeItemAddOnId;
      const placePath = path.split('/', 2).join('/');
      const addOnItem = await context.dataSource.fetchEntity({
        collection: placeItemAddOnCollection,
        path: `${placePath}/menuItemAddOns`,
        entityId: addOnId,
      });
      entity.values.addOnItem = addOnItem?.values.itemName;

      return entity;
    },
    onPreSave: async ({
      values,
      previousValues,
      context,
      collection,
      entityId,
      path,
      status,
    }) => {
      if (previousValues?.addOnItem !== values.addOnItem) {
        values.placeItemAddOnId = values.addOnItem;
        // This line will be remove once all the database migrate to use placeItemAddOnId.
        values.menuItemAddOnId = values.addOnItem;
        delete values.addOnItem;
        return values;
      }
      const newValue = { ...values };
      delete newValue.addOnItem;
      await context.dataSource.saveEntity({
        path: path,
        values: newValue,
        entityId: entityId,
        status: status,
        collection: collection,
      });
    },
  },
});

const addOnGroupsCollection = buildCollection({
  path: 'addOnGroups',
  name: 'Add-On Groups',
  properties: addOnGroupsSchema,
  subcollections: [addOnItemCollection],
});

export const reservationItemCollection = buildCollection({
  name: 'Reservation Items',
  path: 'reservationItems',
  properties: reservationsItemsSchema,
  subcollections: [addOnGroupsCollection],
});

export const menuItemCollection = buildCollection({
  name: 'Menu Items',
  path: 'menuItems',
  properties: menuItemSchema,
  subcollections: [addOnGroupsCollection],

  callbacks: {
    onFetch: async ({ entity }: EntityOnFetchProps) => {
      const availabilitySchedule = entity.values.availabilitySchedule;
      if (
        !availabilitySchedule ||
        Object.keys(availabilitySchedule).length === 0
      ) {
        delete entity.values.availabilitySchedule;
        return entity;
      }

      return entity;
    },

    onPreSave: async ({ values }) => {
      const newValue = { ...values };
      const availabilitySchedule = newValue.availabilitySchedule;
      if (
        !availabilitySchedule ||
        Object.keys(availabilitySchedule).length === 0 ||
        availabilitySchedule.availabilityDays.length === 0
      ) {
        newValue['availabilitySchedule'] = null;
      }
      /// Set old fields to provide information to old versions of the application.
      newValue.menuCategoriesPath = newValue.categoriesPaths;
      cleanEmptyFields(newValue);
      return newValue;
    },
  },
});

export const placeItemAddOnCollection = buildCollection({
  name: 'Add-ons',
  path: 'menuItemAddOns',
  properties: placeItemAddOnSchema,
});

const earningsDepositCollection = buildCollection({
  name: 'Earnings Deposits',
  path: 'earningsDeposits',
  properties: earningsDepositSchema,
  permissions: ({ authController }) => ({
    read: true,
    create: isWithdrawalManager(authController.user),
    edit: isWithdrawalManager(authController.user),
  }),
});

export const placeCollection = buildCollection({
  path: CollectionsNames.places,
  properties: placeSchema,
  name: 'Places',
  permissions: () => ({
    edit: true,
    create: true,
    delete: false,
  }),
  subcollections: [
    menuItemCollection,
    reservationItemCollection,
    placeItemAddOnCollection,
    earningsDepositCollection,
  ],
  callbacks: {
    onFetch: async ({ entity }: EntityOnFetchProps) => {
      const paymentGateways = entity.values.paymentGateways as Record<
        PaymentGateway,
        PlacePaymentGateway
      > | null;
      if (!paymentGateways || Object.keys(paymentGateways).length === 0) {
        delete entity.values.paymentGateways;
      } else {
        entity.values.paymentGateways = Object.entries(paymentGateways)
          .filter((item) => item[1].isEnabled)
          .map((item) => item[0]);
      }

      /// Manage nullable data on place.
      Object.entries(entity.values).forEach(([key, value]) => {
        if (value === null) {
          delete entity.values[key];
        }
      });

      /// updates createdAtInUtc to createAt on subscriptions deposits history.
      if (
        entity.values.subscriptionDeposits &&
        entity.values.subscriptionDeposits.length > 0
      ) {
        entity.values.subscriptionDeposits =
          entity.values.subscriptionDeposits.map(
            (data: { amountInXAF: string; createdAtInUtc: string }) => {
              return {
                amountInXAF: data.amountInXAF,
                createdAt: new Date(data.createdAtInUtc),
              };
            }
          );
      }

      return entity;
    },
    onPreSave: async ({ values, context, previousValues }) => {
      cleanEmptyFields(values);
      const valuesWithUpdatesPaymentGateways =
        getValuesWithUpdatesPaymentGateways(values);

      const newValue = { ...valuesWithUpdatesPaymentGateways };
      const earningsDepositsAccountsNewValue =
        newValue?.earningsDepositsAccounts?.earningsDepositsNumbers;
      const earningsDepositsAccountsPreviousValue =
        previousValues?.earningsDepositsAccounts?.earningsDepositsNumbers;
      if (
        checkIfEarningDepositsAccountHasBeenModified(
          earningsDepositsAccountsNewValue,
          earningsDepositsAccountsPreviousValue
        )
      ) {
        if (!isWithdrawalManager(context.authController.user)) {
          newValue.earningsDepositsAccounts =
            previousValues?.earningsDepositsAccounts;
        }
      }

      if (previousValues?.isActive !== newValue?.isActive) {
        if (!isPlaceActivationManager(context.authController.user)) {
          newValue.isActive = previousValues?.isActive;
        }
      }

      /// Set old fields to provide information to old versions of the application.
      newValue.restaurantCategoriesPath = newValue.categoriesPaths;
      newValue.businessType = newValue.placeType;
      newValue.managersIds = [];

      /// updates createAt to createdAtInUtc on subscriptions deposits history.
      if (newValue.subscriptionDeposits) {
        newValue.subscriptionDeposits = newValue.subscriptionDeposits.map(
          (data: { amountInXAF: string; createdAt: string }) => {
            return {
              amountInXAF: data.amountInXAF,
              createdAtInUtc: new Date(data.createdAt).toISOString(),
            };
          }
        );
        if (newValue.subscriptionDeposits.length === 0) {
          newValue.subscriptionDeposits = null;
        }
      }

      return newValue;
    },
  },
});

function cleanEmptyFields(entity: Partial<any>) {
  Object.entries(entity).forEach(([key, value]) => {
    if (value === '' || value === undefined) {
      entity[key] = null;
    }
  });
}

function getValuesWithUpdatesPaymentGateways(
  values: Partial<any>
): Partial<any> {
  const selectedPaymentGateways = values.paymentGateways;

  if (selectedPaymentGateways == null || selectedPaymentGateways.length === 0) {
    values.paymentGateways = null;
    return values;
  }

  const paymentGateways = Object.values(PaymentGateway)
    .filter(
      (gateway) =>
        gateway === PaymentGateway.cashToCourierPayment ||
        gateway === PaymentGateway.cashToPartnerPayment ||
        gateway === PaymentGateway.mtnMomo ||
        gateway === PaymentGateway.orangeMoney ||
        gateway === PaymentGateway.stripe ||
        gateway === PaymentGateway.stripeWeb ||
        gateway === PaymentGateway.coinsPayment
    )
    .reduce((acc, gateway) => {
      acc[gateway] = {
        isEnabled: selectedPaymentGateways.includes(gateway),
        gateway: gateway,
      };

      return acc;
    }, {} as Record<string, PlacePaymentGateway>);

  values.paymentGateways =
    Object.keys(paymentGateways).length === 0 ? null : paymentGateways;
  return values;
}
