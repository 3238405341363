import { buildCollection } from 'firecms';
import { referralCodeSchema } from '../schemas/referral_code_schema';

export const referralCodesCollection = buildCollection({
  path: 'referralCodes',
  properties: referralCodeSchema,
  name: 'Referral Codes',
  permissions: () => ({
    edit: false,
    create: true,
    delete: false,
  }),
});
