import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { buildProperty } from 'firecms';
import { useState } from 'react';

export const earningsDepositSchema = {
  amountInXAF: buildProperty<number>({
    name: 'Amount (XAF)',
    validation: { required: true, min: 1 },
    dataType: 'number',
  }),
  initiatedBy: buildProperty<string>({
    name: 'Initiated by',
    readOnly: true,
    validation: { required: true },
    dataType: 'string',
    defaultValue: 'SPREELOOP',
    enumValues: {
      SPREELOOP: 'SPREELOOP',
      PARTNER: 'PARTNER',
      COURIER: 'COURIER',
      CUSTOMER: 'CUSTOMER',
    },
  }),
  createdAt: buildProperty<string>({
    name: 'Deposit Date',
    dataType: 'string',
    Field: function ({ setValue, value }) {
      const parsedDate = new Date(value);
      const originalDate =
        !value || isNaN(parsedDate.valueOf()) ? null : parsedDate;
      const [date, setDate] = useState(originalDate);
      return (
        <>
          <DateTimePicker
            renderInput={(props: Record<string, any>) => (
              <TextField {...props} />
            )}
            onChange={(newValue: Date | null) => {
              setDate(newValue);
              if (newValue instanceof Date && !isNaN(newValue.valueOf())) {
                setValue(newValue.toISOString());
              }
            }}
            value={date}
          />
        </>
      );
    },
    Preview: function ({ value }: { value: string }) {
      return <>{new Date(value).toLocaleString()}</>;
    },
    validation: {
      required: true,
      requiredMessage: 'Date of deposit is required',
    },
  }),
};
