import { PropertiesOrBuilders, buildProperty } from 'firecms';
import { PlaceItemAddOn } from '../models/place';

export const placeItemAddOnSchema: PropertiesOrBuilders<PlaceItemAddOn> = {
  itemName: buildProperty<string>({
    name: 'Item Name',
    dataType: 'string',
    validation: { required: true, unique: true, trim: true },
  }),
  additionalPriceInXAF: buildProperty<number>({
    name: 'Additional Price in XAF',
    dataType: 'number',
    validation: {
      required: true,
      min: 0,
      requiredMessage: 'Set a price greater than or equal to 0',
    },
  }),
  additionalNonDiscountedPriceInXAF: buildProperty<number>({
    name: 'Extra non discounted Price in XAF',
    dataType: 'number',
    validation: {
      required: false,
      min: 0,
      requiredMessage: 'Set a price greater than or equal to 0',
    },
  }),
  isAvailable: buildProperty<boolean>({
    defaultValue: false,
    validation: {
      required: true,
    },
    name: 'Available',
    dataType: 'boolean',
  }),
};
