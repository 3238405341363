import { buildCollection } from 'firecms';
import { membershipPlanSchema } from '../schemas/membership_plan_schema';
import { isSpreeloopDeveloper } from '../utils/users_verification';

export const membershipPlanCollection = buildCollection({
  path: 'membershipPlans',
  properties: membershipPlanSchema,
  name: 'Membership Plans',
  permissions: ({ authController }) => ({
    edit: isSpreeloopDeveloper(authController.user),
    create: isSpreeloopDeveloper(authController.user),
    delete: false,
  }),
});
