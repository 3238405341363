export const cuisineTypes = [
  // Countries and regions.
  'African',
  'Cameroonian',
  'Cameroon',
  'Chinese',
  'Italian',
  'Korean',
  'Senegalese',
  'Senegal',
  'Ivorian',
  "Côte D'Ivoire",
  'French',
  "Mets De L'Ouest",
  'Mets Du Centre',
  'Mets Du Littoral',
  'Mets Du Sud',
  "Mets De L'Est",
  'Mets Du Nord-Ouest',
  'Mets Du Sud-Ouest',
  'Mets Du Nord',

  // Proteins.
  'Poulets',
  'Porcs',
  'Gambas',
  'HotDog',
  'Boulettes',
  'Poissons',
  'Crevettes',
  'Boeuf',
  'Calamar',

  // Time of day.
  'Breakfast',
  'Brunch',
  'Dinner',

  // Drinks.
  'Beverage',
  'Boissons',
  'Packs',
  'Whisky',
  'Vodka',
  'Champagnes',
  'Wines',
  'Cigars',

  // Bakeries.
  'Bakery',
  'Galettes',
  'Crêpes',
  'Pancakes',
  'Gaufres',

  // Courses.
  'Menu',
  'Starter',
  'Main Course',
  'Dessert',

  // Specialties.
  'BBQ',
  'Grillades',
  'Menu Combo',
  'Pizza',
  'Shawarma',
  'Hamburger',
  'Tacos',
  'Continental',
  'AddOn',
  'Mocktails',
  'Cocktails',
  'Promotions'
];

/**
 * Contains a order option type.
 */
export class OrderOptionName {
  public static readonly TakeAway = 'takeAway';
  public static readonly DineIn = 'dineIn';
  public static readonly Delivery = 'delivery';
}

/**
 * Contains a social network.
 */
export class SocialNetwork {
  public static readonly Facebook = 'facebook';
  public static readonly Instagram = 'instagram';
  public static readonly Twitter = 'twitter';
}

/**
 * Description phone number cameroon.
 */
export const descriptionPhoneNumberCameroon =
  'Put + with indicator country, e.g: +237690987465.';

/**
 * Network operator.
 */
export enum NetworkOperator {
  ORANGE = 'ORANGE',
  MTN = 'MTN',
}

/**
 * gateway.
 */
export enum PaymentGateway {
  stripe = 'stripe',
  stripeWeb = 'stripe_web',
  mtnMomo = 'mtn_momo',
  orangeMoney = 'orange_money',
  cashToCourierPayment = 'cash_to_courier_payment',
  cashToPartnerPayment = 'cash_to_partner_payment',
  coinsPayment = 'coins_mobile_payment',
  overThePhone = 'over_the_phone_payment',
}

/**
 * The type of subscription.
 */
export enum SubscriptionType {
  printOrders = 'print_orders',
  generateOrdersReceipts = 'generate_orders_receipts',
  editOrdersAfterCreation = 'edit_orders_after_creation',
  createOrderWithPostUi = 'create_order_with_post_ui',
  trackPartnerSubAccountsStatistics = 'track_partner_sub_accounts_statistics',
}

/**
 * appliesOn possible values for promotions.
 */
export enum promotionAppliesOn {
  deliveryFee = 'deliveryFee',
  orderPrice = 'orderPrice',
}

/**
 * appliesOnOrderType possible values for promotions.
 */
export enum promotionAppliesOnOrderType {
  delivery = 'delivery',
}
