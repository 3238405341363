import { promotionSchema } from '../schemas/promotions_schema';
import { isSpreeloopDeveloper } from '../utils/users_verification';
import { EntityCollection, buildCollection } from 'firecms';
import { Promotion } from '../models/promotion';
import { CollectionsNames } from '../utils/database/CollectionsNames';

export const promotionCollection: EntityCollection = buildCollection<
  Partial<Promotion>
>({
  path: CollectionsNames.promotions,
  properties: promotionSchema,
  name: 'Promotions',
  callbacks: {
    onPreSave: async ({ values, context }) => {
      if (!values?.creatorEmail) {
        values.creatorEmail = context.authController.user?.email ?? 'NO_EMAIL';
      }

      return values;
    },
  },
  permissions: ({ authController }) => ({
    edit: isSpreeloopDeveloper(authController.user),
    create: isSpreeloopDeveloper(authController.user),
    delete: isSpreeloopDeveloper(authController.user),
  }),
});
