import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  FieldProps,
  PropertyPreviewProps,
  useSnackbarController,
} from 'firecms';
import { useEffect, useState } from 'react';
import { AvailabilityDay, AvailabilitySchedule, Period } from '../models/place';
import { getColorSchemeForSeed } from '../utils/pick_up_a_color';
import { getIndexOfDay, getNameOfDay, Weekday } from '../utils/week_days_utils';

export function AvailabilityDays({
  value,
}: PropertyPreviewProps<Partial<AvailabilitySchedule>>) {
  const theme = useTheme();
  if (!value) {
    return <Box />;
  }

  const availabilitySchedule = (value as AvailabilitySchedule) ?? {};
  const scheduleDays = [
    ...availabilitySchedule.availabilityDays.map((e) => getNameOfDay(e.day)),
  ];

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(0.5),
      })}
    >
      {scheduleDays &&
        scheduleDays.map((enumValue, index) => {
          const usedColorScheme = getColorSchemeForSeed(enumValue);
          return (
            <Chip
              key={`${index}`}
              sx={{
                maxWidth: '100%',
                backgroundColor: usedColorScheme.color,
                color: usedColorScheme.text,
                fontWeight: theme.typography.fontWeightRegular,
              }}
              size={'medium'}
              variant={'filled'}
              label={enumValue}
            />
          );
        })}
    </Box>
  );
}

export function MenuAvailabilityField({
  value,
  setValue,
}: FieldProps<Partial<AvailabilitySchedule>>) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [validWeek, setValidWeek] = useState<string[]>(
    !value || Object.values(value).length === 0
      ? []
      : [
          ...(value as AvailabilitySchedule).availabilityDays.map((e) =>
            getNameOfDay(e.day)
          ),
        ]
  );
  const [newAvailableDays, setNewAvailableDays] = useState<AvailabilityDay[]>(
    (value && (value as AvailabilitySchedule).availabilityDays) ?? []
  );

  const handleChange = (valueSelect: string[]) => {
    setValidWeek(valueSelect);
    let newListOfDay: AvailabilityDay[] = [];
    for (const newValue of valueSelect) {
      const isOldValue = newAvailableDays.find(
        (e) => e.day === getIndexOfDay(newValue)
      );
      if (!isOldValue) {
        newListOfDay = [
          ...newListOfDay,
          {
            day: getIndexOfDay(newValue),
            periods: [
              {
                endTimeInUTC: { hour: 23, minute: 59 },
                startTimeInUTC: { hour: 0, minute: 0 },
              },
            ],
          },
        ];
      } else {
        newListOfDay = [...newListOfDay, isOldValue];
      }
    }
    const newAvailableSchedule: AvailabilitySchedule = {
      availabilityDays: newListOfDay,
    };
    setValue(newAvailableSchedule);
    setNewAvailableDays(newListOfDay);
  };

  return (
    <FormControl sx={{ m: 1, width: '100%' }}>
      <InputLabel id="availability-multiple-chip-label">
        Available days
      </InputLabel>
      <Select
        labelId="availability-multiple-chip-label"
        id="availability-multiple-chip"
        multiple
        value={validWeek}
        input={
          <OutlinedInput id="select-multiple-chip" label="Available days" />
        }
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((day) => {
              const usedColorScheme = getColorSchemeForSeed(day);
              return (
                <Chip
                  sx={{
                    backgroundColor: usedColorScheme.color,
                    color: usedColorScheme.text,
                  }}
                  key={day}
                  size={'medium'}
                  variant={'filled'}
                  label={day}
                />
              );
            })}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {Object.values(Weekday).map((name) => {
          return (
            <DaysToSelectComponent
              key={name}
              nameOfDay={name}
              validWeek={validWeek}
              handleChange={handleChange}
            />
          );
        })}
      </Select>
      {validWeek &&
        validWeek.map((week, index) => {
          return (
            <PeriodComponent
              key={index}
              week={week}
              newAvailableDay={newAvailableDays}
              setNewAvailableDay={setNewAvailableDays}
            />
          );
        })}
    </FormControl>
  );
}

const DaysToSelectComponent = (props: {
  nameOfDay: Weekday;
  validWeek: string[];
  handleChange: (value: string[]) => void;
}) => {
  const snackbarController = useSnackbarController();
  const [isChecked, updateIsChecked] = useState<boolean>(
    props.validWeek.indexOf(props.nameOfDay) > -1
  );

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (!event.target.checked) {
      if (props.validWeek.length === 1) {
        snackbarController.open({
          type: 'error',
          message: 'The list of availability day cannot be empty',
        });
        return;
      }
      props.validWeek.splice(props.validWeek.indexOf(props.nameOfDay), 1);
      props.handleChange([...props.validWeek]);
      updateIsChecked(event.target.checked);
      return;
    }
    props.handleChange([...props.validWeek, props.nameOfDay]);
    updateIsChecked(event.target.checked);
  };

  return (
    <MenuItem key={props.nameOfDay} value={props.nameOfDay}>
      <Checkbox
        checked={isChecked}
        defaultChecked={props.validWeek.indexOf(props.nameOfDay) > -1}
        onChange={handleCheckBoxChange}
      />
      <ListItemText primary={props.nameOfDay} />
    </MenuItem>
  );
};

const PeriodComponent = (props: {
  week: string;
  newAvailableDay: AvailabilityDay[];
  setNewAvailableDay: (arg: AvailabilityDay[]) => void;
}) => {
  const availableDay = props.newAvailableDay.find(
    (availableDay) => availableDay.day === getIndexOfDay(props.week)
  );

  const [listOfPeriods, setListOfPeriods] = useState<Period[]>(
    (availableDay && availableDay.periods) ?? []
  );
  useEffect(() => {
    if (availableDay) {
      availableDay.periods = listOfPeriods;
    }
  }, [availableDay, listOfPeriods]);

  /**
   * Adds a new period.
   */
  const addNewPeriod = () => {
    setListOfPeriods([
      ...listOfPeriods,
      {
        endTimeInUTC: { hour: 23, minute: 59 },
        startTimeInUTC: { hour: 0, minute: 0 },
      },
    ]);
  };
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{props.week}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {listOfPeriods &&
            listOfPeriods.map((period, index) => {
              return (
                <PeriodItemComponent
                  key={index}
                  period={period}
                  index={index}
                  lisOfPeriods={listOfPeriods}
                  setLisOfPeriods={setListOfPeriods}
                />
              );
            })}
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              startIcon={<AddIcon />}
              onClick={addNewPeriod}
            >
              New Period
            </Button>
          </Stack>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

function StartTimeComponent(props: {
  period: Period;
  onChangeStartTimeHour: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChangeStartTimeMinute: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) {
  return (
    <Stack direction="row" spacing={2}>
      <Box
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        Start Time:
      </Box>
      <TextField
        type={'number'}
        style={{
          flex: 1,
        }}
        id={`start-hour-time-text-${Math.random()}`}
        defaultValue={props.period.startTimeInUTC.hour}
        onChange={props.onChangeStartTimeHour}
        inputProps={{
          min: 0,
          max: 23,
          pattern: '([01]?[0-9]|2[0-3])',
        }}
        label="Hours"
      />
      <TextField
        style={{
          flex: 1,
        }}
        id={`start-min-time-text-${Math.random()}`}
        type={'number'}
        defaultValue={props.period.startTimeInUTC.minute}
        onChange={props.onChangeStartTimeMinute}
        inputProps={{
          min: 0,
          max: 59,
          pattern: '[0-5][0-9]',
        }}
        label="Minutes"
      />
    </Stack>
  );
}

function EndTimeComponent(props: {
  period: Period;
  onChangeEndTimeHour: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChangeStartTimeMinute: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) {
  return (
    <Stack direction="row" spacing={2}>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        End Time:
      </Box>
      <TextField
        id={`end-hour-time-text-${Math.random()}`}
        type={'number'}
        style={{
          flex: 1,
        }}
        defaultValue={props.period.endTimeInUTC.hour}
        onChange={props.onChangeEndTimeHour}
        inputProps={{
          min: 0,
          max: 23,
          pattern: '([01]?[0-9]|2[0-3])',
        }}
        label="Hours"
      />
      <TextField
        id={`end-min-time-text-${Math.random()}`}
        type={'number'}
        style={{
          flex: 1,
        }}
        defaultValue={props.period.endTimeInUTC.minute}
        inputProps={{
          min: 0,
          max: 59,
          pattern: '[0-5][0-9]',
        }}
        onChange={props.onChangeStartTimeMinute}
        label="Minutes"
      />
    </Stack>
  );
}

const PeriodItemComponent = (props: {
  period: Period;
  lisOfPeriods: Period[];
  index: number;
  setLisOfPeriods: (arg: Period[]) => void;
}) => {
  const snackbarController = useSnackbarController();

  return (
    <Box
      key={`${Math.random()}`}
      sx={{
        width: '95%',
        p: 2,
        border: '1px solid grey',
        marginBottom: '12px',
        borderRadius: '8px',
      }}
    >
      <Stack spacing={2}>
        <StartTimeComponent
          period={props.period}
          onChangeStartTimeHour={(event) => {
            if (
              parseInt(event.target.value) > 23 ||
              parseInt(event.target.value) < 0
            ) {
              snackbarController.open({
                type: 'error',
                message: 'invalid start time hour',
              });
            } else {
              props.period.startTimeInUTC.hour = parseInt(event.target.value);
            }
          }}
          onChangeStartTimeMinute={(event) => {
            if (
              parseInt(event.target.value) > 59 ||
              parseInt(event.target.value) < 0
            ) {
              snackbarController.open({
                type: 'error',
                message: 'invalid start time minute',
              });
            } else {
              props.period.startTimeInUTC.minute = parseInt(event.target.value);
            }
          }}
        />
        <EndTimeComponent
          period={props.period}
          onChangeEndTimeHour={(event) => {
            if (
              parseInt(event.target.value) > 23 ||
              parseInt(event.target.value) < 0
            ) {
              snackbarController.open({
                type: 'error',
                message: 'invalid end time hour',
              });
            } else {
              props.period.endTimeInUTC.hour = parseInt(event.target.value);
            }
          }}
          onChangeStartTimeMinute={(event) => {
            if (
              parseInt(event.target.value) > 59 ||
              parseInt(event.target.value) < 0
            ) {
              snackbarController.open({
                type: 'error',
                message: 'invalid end time minute',
              });
            } else {
              props.period.endTimeInUTC.minute = parseInt(event.target.value);
            }
          }}
        />
        {props.lisOfPeriods.length > 1 ? (
          <Button
            onClick={() => {
              props.lisOfPeriods.splice(props.index, 1);
              props.setLisOfPeriods([...props.lisOfPeriods]);
            }}
            size="small"
            color="primary"
            variant="outlined"
            style={{
              justifyContent: 'flex-end',
              alignSelf: 'flex-end',
            }}
          >
            remove
          </Button>
        ) : (
          <Box />
        )}
      </Stack>
    </Box>
  );
};
