import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import {
  FieldProps,
  PropertyPreviewProps,
  useDataSource,
  useSnackbarController,
} from 'firecms';
import { useCallback, useEffect, useState } from 'react';
import { categoryCollection } from '../../collections/categories_collection';
import { Category } from '../../models/category';
import { CollectionsNames } from '../../utils/database/CollectionsNames';
import {
  getDatabasePath,
  getDocumentId,
} from '../../utils/database/path_utils';
import { CHIP_COLORS } from '../../utils/pick_up_a_color';

interface CategoryAugmented {
  path: string;
  id: string;
  value: Category;
  label: string;
  isSelected: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const CategoriesPathFields = ({
  value,
  isSubmitting,
  setValue,
  showError,
  error,
}: FieldProps<string[]>) => {
  const [placeCategoriesPath, setPlaceCategoriesPath] = useState<
    string[] | undefined
  >(value);

  const [categoriesAugmented, setCategoriesAugmented] = useState<
    CategoryAugmented[]
  >([]);

  const dataSource = useDataSource();

  const setCategoriesPath = useCallback(() => {
    if (value !== undefined) {
      const numEmptyValues = value.filter(
        (value) => value.trim() === ''
      ).length;
      if (numEmptyValues !== 0) {
        setValue(value.filter((value) => value.trim() !== ''));
        setPlaceCategoriesPath(value);
      }
      return;
    }
    setValue([]);
    setPlaceCategoriesPath([]);
  }, [setValue, value]);

  const handleChange = (category: CategoryAugmented) => {
    categoriesAugmented[categoriesAugmented.indexOf(category)].isSelected =
      category.isSelected;
    setCategoriesAugmented([...categoriesAugmented]);

    if (category.isSelected) {
      setPlaceCategoriesPath([...value, category.path]);
      setValue([...value, category.path]);
    } else {
      setValue(
        value.filter((_, index) => index !== value.indexOf(category.path))
      );
      setPlaceCategoriesPath(value);
    }
  };

  const fetchAllCategories = useCallback(async () => {
    const categories = await dataSource.fetchCollection({
      path: CollectionsNames.categories,
      collection: categoryCollection,
    });
    const enumCategoriesRecord: CategoryAugmented[] =
      categories.map<CategoryAugmented>((item) => {
        const label = `fr: ${item.values.internationalizedTitle.fr} || en: ${item.values.internationalizedTitle.en} || priority: ${item.values.priority}`;
        const path = getDatabasePath(item.path, item.id);
        return {
          id: item.id,
          label: label,
          path: path,
          value: item.values,
          isSelected: placeCategoriesPath?.includes(path) ?? false,
        };
      });
    setCategoriesAugmented(enumCategoriesRecord);
  }, [dataSource, placeCategoriesPath]);

  useEffect(() => {
    setCategoriesPath();
  }, [setCategoriesPath]);

  useEffect(() => {
    fetchAllCategories();
  }, [fetchAllCategories]);

  return (
    <FormControl sx={{ m: 1, width: '100%' }}>
      <InputLabel id="demo-multiple-checkbox-label">Categories</InputLabel>
      <Select
        sx={{
          minHeight: '64px',
        }}
        multiple={true}
        variant={'filled'}
        value={categoriesAugmented}
        disabled={isSubmitting}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected: CategoryAugmented[]) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(
              (value: CategoryAugmented) =>
                value.isSelected && <Chip key={value.id} label={value.label} />
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {categoriesAugmented &&
          categoriesAugmented.map((categoryAugmented) => {
            return (
              <CategoriesSelectComponent
                key={categoryAugmented.id}
                categoryAugmented={categoryAugmented}
                handleChange={handleChange}
                categoriesAugmented={categoriesAugmented}
              />
            );
          })}
      </Select>
      {showError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const CategoriesSelectComponent = ({
  categoryAugmented,
  categoriesAugmented,
  handleChange,
}: {
  categoryAugmented: CategoryAugmented;
  categoriesAugmented: CategoryAugmented[];
  handleChange: (value: CategoryAugmented) => void;
}) => {
  const snackbarController = useSnackbarController();

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (!event.target.checked) {
      const isSelectedCount = categoriesAugmented.filter(
        (category) => category.isSelected
      ).length;
      if (isSelectedCount === 1) {
        snackbarController.open({
          type: 'error',
          message: 'The list of categories path cannot be empty',
        });
        return;
      }
    }
    categoryAugmented.isSelected = event.target.checked;
    handleChange(categoryAugmented);
  };

  return (
    <MenuItem key={categoryAugmented.id} value={categoryAugmented.label}>
      <Checkbox
        checked={categoryAugmented.isSelected}
        onChange={handleCheckBoxChange}
      />
      <ListItemText primary={categoryAugmented.label} />
    </MenuItem>
  );
};

export function CategoriesPathPreView({
  value,
}: PropertyPreviewProps<string[]>) {
  const dataSource = useDataSource();
  const [categoriesAugmented, setCategoriesAugmented] = useState<
    CategoryAugmented[]
  >([]);

  const fetchAllCategories = useCallback(async () => {
    if (value) {
      const categories: any = [];
      for (const element of value) {
        const id = getDocumentId(element);
        const category = await dataSource.fetchEntity({
          path: CollectionsNames.categories,
          entityId: id,
          collection: categoryCollection,
        });
        if (!category) {
          continue;
        }
        const label = category.values.internationalizedTitle.fr;
        const path = getDatabasePath(category.path, category.id);
        const categoryAugmented = {
          id: category.id,
          label: label,
          path: path,
          value: category.values,
          isSelected: true,
        };
        categories.push(categoryAugmented);
      }
      setCategoriesAugmented(categories);
    }
  }, [dataSource, value]);

  useEffect(() => {
    fetchAllCategories();
  }, [fetchAllCategories]);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(0.5),
      })}
    >
      {categoriesAugmented.map((categoryAugmented) => {
        const colorKey = Object.keys(CHIP_COLORS);
        const color = Math.floor(Math.random() * colorKey.length);
        const usedColorScheme = CHIP_COLORS[colorKey[color]];
        return (
          <Chip
            key={categoryAugmented.id}
            sx={{
              backgroundColor: usedColorScheme.color,
              color: usedColorScheme.text,
            }}
            size={'medium'}
            label={categoryAugmented.label}
          />
        );
      })}
    </Box>
  );
}
