import { buildProperty } from 'firecms';

export const userSchema = {
  firstName: buildProperty<string>({
    readOnly: true,
    name: 'First Name',
    description: 'The user first name',
    dataType: 'string',
    validation: { required: true },
  }),
  lastName: buildProperty<string>({
    readOnly: true,
    name: 'Last Name',
    description: 'The user last name',
    dataType: 'string',
    validation: { required: true },
  }),
  email: buildProperty<string>({
    readOnly: true,
    name: 'Email',
    description: 'The user email',
    dataType: 'string',
    validation: { required: false },
  }),
  userPhoneNumber: buildProperty<string>({
    readOnly: true,
    name: 'Phone Number',
    description: 'The phone number',
    dataType: 'string',
    validation: { required: true },
  }),
};
