import { buildCollection } from 'firecms';
import { withdrawalTransactionsAuditsSchema } from '../schemas/withdrawal_transactions_audits_schema';
import { isAuditManager } from '../utils/users_verification';

export const withdrawalTransactionsAuditsCollection = buildCollection({
  path: 'withdrawalTransactionsAudits',
  properties: withdrawalTransactionsAuditsSchema,
  name: 'Withdrawal transactions audits',
  permissions: ({ authController }) => ({
    edit: isAuditManager(authController.user),
    read: isAuditManager(authController.user),
    create: false,
    delete: false,
  }),
});
