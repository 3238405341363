import { buildCollection, EntityOnFetchProps, FireCMSContext } from 'firecms';
import { Place } from '../models/place';
import { partnerSchema } from '../schemas/partners_schema';
import { CollectionsNames } from '../utils/database/CollectionsNames';
import { getDocumentId } from '../utils/database/path_utils';
import {
  isAccountManager,
  isWithdrawalManager,
} from '../utils/users_verification';
import { placeCollection } from './places_collection';

export const partnerCollection = buildCollection({
  path: 'partners',
  properties: partnerSchema,
  name: 'Partners',
  callbacks: {
    onFetch: async ({ context, entity }: EntityOnFetchProps) => {
      const activePlaceBrandName = await getActivePlaceBrandName(
        entity.values,
        context
      );
      if (activePlaceBrandName) {
        entity.values.activePlaceBrandName = activePlaceBrandName;
      }
      return entity;
    },
    onPreSave: async ({ values, context, previousValues }) => {
      const newValue = { ...values };
      const canMakeEarningsWithdrawalsNewValue =
        newValue.canMakeEarningsWithdrawals;
      const canMakeEarningsWithdrawalsPreviousValue =
        previousValues?.canMakeEarningsWithdrawals;
      const validationStatusNewValue = newValue.validationStatus;
      const validationStatusPreviousValue = previousValues?.validationStatus;
      if (
        canMakeEarningsWithdrawalsNewValue !==
        canMakeEarningsWithdrawalsPreviousValue
      ) {
        if (!isWithdrawalManager(context.authController.user)) {
          newValue.canMakeEarningsWithdrawals =
            previousValues?.canMakeEarningsWithdrawals;
        }
      }

      const canEditPermissionsForAllPlacesNewValue =
        newValue.canEditPermissionsForAllPlaces;
      const canEditPermissionsForAllPlacesPreviousValue =
        previousValues?.canEditPermissionsForAllPlaces;
      if (
        canEditPermissionsForAllPlacesNewValue !==
        canEditPermissionsForAllPlacesPreviousValue
      ) {
        if (!isAccountManager(context.authController.user)) {
          newValue.canEditPermissionsForAllPlaces =
            previousValues?.canEditPermissionsForAllPlaces;
        }
      }
      if (validationStatusNewValue !== validationStatusPreviousValue) {
        if (!isAccountManager(context.authController.user)) {
          newValue.validationStatus = previousValues?.validationStatus;
        }
      }
      delete newValue.activePlaceBrandName;
      return newValue;
    },
  },
  permissions: () => ({
    edit: true,
    create: false,
    delete: false,
  }),
});

const getActivePlaceBrandName = async (
  values: Record<string, unknown>,
  context: FireCMSContext
): Promise<string | undefined> => {
  const placeId = getDocumentId(values.placePath as string);
  if (!placeId) {
    return;
  }
  const placeDoc = await context.dataSource.fetchEntity<Place>({
    collection: placeCollection,
    path: CollectionsNames.places,
    entityId: placeId,
  });

  return placeDoc?.values?.brandName;
};
