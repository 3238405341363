import { buildCollection } from 'firecms';
import { CarouselItemSchema } from '../schemas/carousel_item_schema';
import {
  isSpreeloopDeveloper,
  isSpreeloopUser,
} from '../utils/users_verification';

export const carouselItemsCollection = buildCollection({
  path: 'carouselItems',
  properties: CarouselItemSchema,
  name: 'Carousel',
  permissions: ({ authController }) => ({
    edit: isSpreeloopUser(authController.user),
    create: isSpreeloopUser(authController.user),
    delete: isSpreeloopDeveloper(authController.user),
  }),
});
