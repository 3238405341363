import { buildCollection, EntityOnFetchProps } from 'firecms';
import { Courier } from '../models/courier';
import { checkIfEarningDepositsAccountHasBeenModified } from '../utils/eranings_deposits_account/eranings_deposits_account';
import { formatUserNameAbbreviateLastName } from '../utils/format_user_name_abbreviate_last_name';
import { courierSchema } from '../schemas/couriers_schema';
import { earningsDepositSchema } from '../schemas/earnings_deposit_schema';
import {
  isAccountManager,
  isSpreeloopDeveloper,
  isWithdrawalManager,
} from '../utils/users_verification';
import { userCollection } from './users_collection';

const earningsDepositCollection = buildCollection({
  name: 'Earnings Deposits',
  path: 'earningsDeposits',
  properties: earningsDepositSchema,
  permissions: ({ authController }) => ({
    read: true,
    create: isWithdrawalManager(authController.user),
  }),
});

export const courierCollection = buildCollection({
  path: 'couriers',
  properties: courierSchema,
  name: 'Couriers',
  callbacks: {
    onFetch: async ({ context, entity }: EntityOnFetchProps<Courier>) => {
      const userId = entity.id;
      const courierUser = await context.dataSource.fetchEntity({
        collection: userCollection,
        path: 'users',
        entityId: userId,
      });
      const firstName = courierUser?.values.firstName ?? '';
      const lastName = courierUser?.values.lastName ?? '';
      const phoneNumber = courierUser?.values.userPhoneNumber ?? '';

      entity.values.fullName = formatUserNameAbbreviateLastName(
        firstName,
        lastName
      );
      entity.values.phoneNumber = phoneNumber;
      return entity;
    },
    onPreSave: async ({ values, context, previousValues }) => {
      const newValue = { ...values };
      const earningsDepositsAccountsNewValue =
        newValue?.earningsDepositsAccounts?.earningsDepositsNumbers;
      const earningsDepositsAccountsPreviousValue =
        previousValues?.earningsDepositsAccounts?.earningsDepositsNumbers;
      const validationStatusNewValue = newValue.validationStatus;
      const validationStatusPreviousValue = previousValues?.validationStatus;
      if (
        checkIfEarningDepositsAccountHasBeenModified(
          earningsDepositsAccountsNewValue,
          earningsDepositsAccountsPreviousValue
        )
      ) {
        if (!isWithdrawalManager(context.authController.user)) {
          newValue.earningsDepositsAccounts =
            previousValues?.earningsDepositsAccounts;
        }
      }
      if (validationStatusNewValue !== validationStatusPreviousValue) {
        if (!isAccountManager(context.authController.user)) {
          newValue.validationStatus = previousValues?.validationStatus;
        }
      }
      return newValue;
    },
  },
  permissions: ({ authController }) => ({
    edit: isSpreeloopDeveloper(authController.user),
    create: isAccountManager(authController.user),
    delete: isAccountManager(authController.user),
  }),
  subcollections: [earningsDepositCollection],
});
