import { buildCollection } from 'firecms';
import { mapZonesSchema } from '../schemas/map_zone_schema';
import {
  isSpreeloopDeveloper,
  isSpreeloopUser,
} from '../utils/users_verification';

export const mapZoneCollection = buildCollection({
  path: 'mapZones',
  properties: mapZonesSchema,
  name: 'MapZones',
  permissions: ({ authController }) => ({
    edit: isSpreeloopUser(authController.user),
    create: isSpreeloopUser(authController.user),
    delete: isSpreeloopDeveloper(authController.user),
  }),
});
