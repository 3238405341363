import { buildProperty } from 'firecms';
import { WithdrawalTransactionAuditStatus } from '../models/withdrawal_transactions_audits';
import { PaymentGateway } from '../utils/constants';

export const withdrawalTransactionsAuditsSchema = {
  earningWithdrawalPath: buildProperty<string>({
    name: 'Earnings Withdrawal Path',
    validation: { required: true },
    readOnly: true,
    dataType: 'string',
  }),
  notes_from_reviewer: buildProperty<string>({
    name: 'Additional Notes',
    validation: { required: false, trim: true },
    readOnly: false,
    dataType: 'string',
  }),
  notes_from_audit_system: buildProperty<string>({
    name: 'Notes from audit system',
    validation: { required: true },
    readOnly: true,
    dataType: 'string',
  }),
  gateway: buildProperty<string>({
    name: 'Payment Provider',
    description: 'The provider used to make the withdrawal.',
    readOnly: true,
    validation: { required: true },
    dataType: 'string',
    enumValues: PaymentGateway,
  }),
  status: buildProperty<string>({
    name: 'Audit Status',
    validation: { required: true },
    dataType: 'string',
    enumValues: WithdrawalTransactionAuditStatus,
  }),
};
