import { EarningsDepositsNumber } from '../../models/place';

/**
 * Checks if an earning deposits account has been modified
 * @param newValue - The new EarningsDepositsNumber array
 * @param previousValue - The previous EarningsDepositsNumber array
 * @returns true if the arrays are different, false otherwise
 */
export function checkIfEarningDepositsAccountHasBeenModified(
  newValue: EarningsDepositsNumber[] | undefined | null,
  previousValue: EarningsDepositsNumber[] | undefined | null
): boolean {
  // If both arrays are undefined or null, they haven't been modified
  if (!newValue && !previousValue) {
    return false;
  }

  // If only one of the arrays is undefined or null, they have been modified
  if (!newValue || !previousValue) {
    return true;
  }

  // If the arrays have different lengths, they have been modified
  if (newValue.length !== previousValue.length) {
    return true;
  }

  // If any of the elements in the arrays are different, they have been modified
  for (let i = 0; i < newValue.length; i++) {
    if (
      newValue[i].phoneNumber !== previousValue[i].phoneNumber ||
      newValue[i].networkOperator !== previousValue[i].networkOperator
    ) {
      return true;
    }
  }

  // If none of the above conditions are met, the arrays are identical
  return false;
}
