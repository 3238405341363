import { buildProperty, PropertiesOrBuilders } from 'firecms';
import { Courier } from '../models/courier';
import {
  EarningsDepositsAccount,
  EarningsDepositsNumber,
} from '../models/place';
import {
  descriptionPhoneNumberCameroon,
  NetworkOperator,
} from '../utils/constants';
import { regexPhoneNumberCameroon } from '../utils/regexs';

enum ValidationStatus {
  pending = 'pending',
  suspended = 'suspended',
  approved = 'approved',
}

export const courierSchema: PropertiesOrBuilders<Courier> = {
  canAcceptMultipleOrders: buildProperty<boolean>({
    name: 'Can Accept Multiple Orders',
    readOnly: false,
    defaultValue: false,
    dataType: 'boolean',
  }),
  drivingLicencePath: buildProperty<string>({
    name: 'Driving Licence',
    readOnly: true,
    validation: { required: false },
    dataType: 'string',
    storage: {
      storagePath: 'couriers/<user-id>/driving_licence',
      acceptedFiles: ['image/*'],
    },
  }),
  nationalIdentityPath: buildProperty<string>({
    name: 'National Identity',
    readOnly: true,
    validation: { required: true },
    dataType: 'string',
    storage: {
      storagePath: 'couriers/<user-id>/national_identity',
      acceptedFiles: ['image/*'],
    },
  }),
  fullName: buildProperty<string>({
    readOnly: true,
    name: 'Name',
    description: 'The Courier Name',
    dataType: 'string',
  }),
  phoneNumber: buildProperty<string>({
    readOnly: true,
    name: 'Phone Number',
    description: 'The Courier Phone Number',
    dataType: 'string',
  }),
  validationStatus: buildProperty<string>({
    name: 'Validate',
    dataType: 'string',
    enumValues: ValidationStatus,
  }),
  earningsPercentage: buildProperty<number>({
    name: 'Earnings Percentage',
    validation: { min: 1, max: 100 },
    dataType: 'number',
  }),
  earningsDepositsAccounts: buildProperty<EarningsDepositsAccount>({
    name: 'Earnings Deposits Accounts',
    dataType: 'map',
    properties: {
      earningsDepositsNumbers: buildProperty<EarningsDepositsNumber[]>({
        name: 'Earnings Deposits Numbers',
        validation: { uniqueInArray: true },
        dataType: 'array',
        of: {
          dataType: 'map',
          properties: {
            phoneNumber: {
              name: 'Phone Number',
              validation: {
                required: true,
                matches: regexPhoneNumberCameroon,
              },
              description: descriptionPhoneNumberCameroon,
              dataType: 'string',
            },
            networkOperator: {
              name: 'Network Operator',
              validation: {
                required: true,
              },
              dataType: 'string',
              enumValues: NetworkOperator,
            },
          },
        },
      }),
    },
  }),
};
