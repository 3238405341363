import {
  NetworkOperator,
  PaymentGateway,
  SubscriptionType,
} from '../utils/constants';

export type EarningsDepositsNumber = {
  phoneNumber: string;
  networkOperator: NetworkOperator;
};

type OpeningTimes = {
  openingDay: OpeningDay[];
};

type OpeningDay = {
  day: number;
  openingPeriods: OpeningPeriod[];
};

type OpeningPeriod = {
  openTimeInUTC: {
    hour: number;
    minute: number;
  };
  closeTimeInUTC: {
    hour: number;
    minute: number;
  };
};

export enum ServiceFeesComputationVersion {
  version1 = 'version_1',
  version2 = 'version_2',
  version3 = 'version_3',
}

export enum PlaceType {
  restaurant = 'restaurant',
  sportsCenter = 'sports_center',
}

export enum ReservationType {
  soccerStadium = 'soccer_stadium',
}

export type AvailabilitySchedule = {
  availabilityDays: AvailabilityDay[];
};
export type AvailabilityDay = {
  day: number;
  periods: Period[];
};
export type Period = {
  startTimeInUTC: Time;
  endTimeInUTC: Time;
};
export type Time = {
  hour: number;
  minute: number;
};

export type EarningsDepositsAccount = {
  earningsDepositsNumbers: EarningsDepositsNumber[];
};

export type PlaceItemAddOn = {
  isAvailable: boolean;
  additionalPriceInXAF: number;
  additionalNonDiscountedPriceInXAF?: number;
  itemName: string;
};

export type AddOnGroup = {
  description: string;
  required: boolean;
  multiSelect: boolean;
};

export type AddOnItem = {
  isAvailable: boolean;
  additionalPriceInXAF: number;
  additionalNonDiscountedPriceInXAF?: number;
  /** @deprecated Deprecated since 11/07/2024 Use placeItemAddOnId instead */
  menuItemAddOnId: string;
  placeItemAddOnId: string;
};

export interface MenuItem extends BaseItem {
  foodName: string;
  foodType: string;
  numberOfPerson: number;
  priceInXAF: number;
  packageFee: number;
  isVegetarian: boolean;
  cookingTimeInMinutes: {
    min: number;
    max: number;
  };
}

export interface ReservationItem extends BaseItem {
  type: ReservationType;
  name: string;
  pricePerHourInXAF: number;
  pricePerDayInXAF: number;
}

export type BaseItem = {
  availabilitySchedule: AvailabilitySchedule;
  shortDescription: string;
  longDescription?: string;
  /** @deprecated Deprecated since 1/05/2024 use categoriesPaths instead */
  menuCategoriesPath: string[];
  categoriesPaths: string[];
  imagePath: string;
  isAvailable: boolean;
  isVisible?: boolean;
};

export type Place = {
  brandName: string;
  description: string;
  isActive: boolean;
  categoriesPaths: string[];
  address: {
    street: string;
    city: string;
    country: string;
    lat: number;
    lng: number;
    postalCode: string;
    additionalInformation: string;
  };
  /** @deprecated Deprecated since 1/05/2024 use categoriesPaths instead */
  restaurantCategoriesPath: string[];
  cuisineType: string[];
  orderOptions: string[];
  images: {
    thumbPath: string;
    bannerPath: string;
    previewsPath: string;
  };
  managersIds: string[];
  earningsDepositsAccounts: EarningsDepositsAccount;
  contacts: {
    phoneNumbers: string[];
    emails: string[];
    smsNotifications: string[];
    website: string;
    socials: {
      name: string;
      uris: string[];
    }[];
  };
  rating: number;
  numberOfReviews: number;
  openingTimes?: OpeningTimes;
  paymentGateways?: Record<PaymentGateway, PlacePaymentGateway>;
  subscriptions?: Record<SubscriptionType, SubscriptionData>;
  serviceFeesComputationVersion?: ServiceFeesComputationVersion | null;
  subscriptionPath?: string | null;
  subscriptionHistoryPaths?: string[];
  webDeepLink?: string;
  dynamicDeepLink?: string;
  /** @deprecated Deprecated since 1/05/2024 use placeType instead */
  businessType?: PlaceType;
  placeType?: PlaceType;
  averageCookingTimeInMinutes: {
    min: number;
    max: number;
  };
  tables?: Record<string, PlaceTable>;
  /** @deprecated Deprecated since 8/05/2024 use tables instead */
  restaurantTables?: Record<string, RestaurantTable>;
  subscriptionDeposits?: SubscriptionDeposit[];
  nextSubscriptionPath?: string | null;
};

export type PlacePaymentGateway = {
  isEnabled: boolean;
  gateway: string;
};
export type SubscriptionData = {
  isEnabled: boolean;
  title: string;
};

export type PlaceTable = {
  tableName: string;
  webDeepLink?: string | null;
};

export type RestaurantTable = {
  dineInTable: string;
  dineInWebDeepLink?: string;
};

export interface SubscriptionDeposit {
  amountInXAF: number;
  createdAt: string;
}
