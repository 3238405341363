import { EnumValueConfig, buildProperty } from 'firecms';
import moment from 'moment';
import { SubscriptionStatus } from '../models/subscription';
import SubscriptionMembershipPathField, {
  SubscriptionSubscriberPathField,
} from '../widgets/subscription_widget';

const status: EnumValueConfig[] = Object.entries(SubscriptionStatus).map(
  ([key, value]) => {
    const id = value;
    const label = key;
    return { id, label };
  }
);
export const subscriptionSchema = {
  membershipPlanPath: buildProperty({
    name: 'Membership Plan Path',
    dataType: 'string',
    validation: { required: true },
    Field: SubscriptionMembershipPathField,
  }),
  status: buildProperty<string>({
    name: 'Status',
    dataType: 'string',
    validation: { required: true },
    enumValues: status,
  }),
  priceInXAF: buildProperty({
    name: 'Price In XAF',
    dataType: 'number',
    readOnly: true,
  }),

  subscriberPath: buildProperty<string>({
    name: 'Subscriber Path',
    dataType: 'string',
    validation: { required: true },
    Field: SubscriptionSubscriberPathField,
  }),
  startDate: buildProperty({
    name: 'Start Date',
    dataType: 'date',
    mode: 'date',
    validation: { required: true },
    Preview: function ({ value }: { value: any }) {
      return <>{moment(value).format('MMMM Do YYYY')}</>;
    },
  }),
  endDate: buildProperty({
    name: 'End Date',
    dataType: 'string',
    readOnly: true,
  }),
  membershipPlan: buildProperty({
    name: 'Membership Plan',
    dataType: 'map',
    readOnly: true,
    keyValue: true,
  }),
};
