import { buildProperty } from 'firecms';

export const mapZonesSchema = {
  name: buildProperty<string>({
    name: 'Name of the zone',
    validation: { required: true, trim: true },
    dataType: 'string',
  }),
  active: buildProperty<boolean>({
    name: 'Active',
    validation: { required: true },
    dataType: 'boolean',
  }),
  center: buildProperty({
    name: 'Center of this zone',
    validation: { required: true },
    dataType: 'map',
    properties: {
      lat: buildProperty<number>({
        name: 'Latitude',
        validation: {
          required: true,
          min: -90,
          max: 90,
        },
        dataType: 'number',
      }),
      lng: buildProperty<number>({
        name: 'Longitude',
        validation: {
          required: true,
          min: -180,
          max: 180,
        },
        dataType: 'number',
      }),
    },
  }),
  polygon: buildProperty({
    name: 'Polygon coordinates',
    validation: { required: true },
    dataType: 'array',
    of: {
      dataType: 'map',
      properties: {
        lat: {
          name: 'Latitude',
          validation: {
            required: true,
            min: -90,
            max: 90,
          },
          dataType: 'number',
        },
        lng: {
          name: 'Longitude',
          validation: {
            required: true,
            min: -180,
            max: 180,
          },
          dataType: 'number',
        },
      },
    },
  }),
};
