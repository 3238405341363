import { PropertiesOrBuilders, buildProperty } from 'firecms';
import { CarouselItem } from '../models/carousel_item';

export const CarouselItemSchema: PropertiesOrBuilders<CarouselItem> = {
  priority: buildProperty<number>({
    name: 'Priority',
    description: 'Descending order of display',
    validation: {
      required: true,
      min: 1,
      max: 999,
      unique: true,
    },
    dataType: 'number',
  }),
  internalRoute: buildProperty<string>({
    name: 'Internal Route',
    description: 'e.g.: restaurants/XYZ',
    validation: {
      required: false,
    },
    dataType: 'string',
  }),
  imagePath: buildProperty<string>({
    name: 'Image',
    description: 'The image path',
    validation: { required: true },
    dataType: 'string',
    storage: {
      storagePath: 'carousel_items',
      acceptedFiles: ['image/*'],
    },
  }),
  active: buildProperty<boolean>({
    name: 'Active',
    description: 'Whether the item should be visible',
    validation: {
      required: true,
    },
    dataType: 'boolean',
  }),
};
