import { MembershipPlan } from './membership_plan';

export interface Subscription {
  startDate: string;
  endDate: string;
  membershipPlanPath: string;
  status: SubscriptionStatus;
  priceInXAF: number;
  subscriberPath: string;
  membershipPlan: MembershipPlan;
  startDateInUtc: string;
  endDateInUtc: string;
}

export enum SubscriptionStatus {
  CANCELED_BY_SPREELOOP = 'CANCELED_BY_SPREELOOP',
  PENDING = 'PENDING',
  ACTIVATED = 'ACTIVATED',
  EXPIRED = 'EXPIRED',
}
