import { EnumValueConfig, buildProperty } from 'firecms';
import { AvailabilitySchedule, ReservationType } from '../models/place';
import {
  CategoriesPathFields,
  CategoriesPathPreView,
} from '../widgets/categories/categories_utils';
import {
  AvailabilityDays,
  MenuAvailabilityField as CustomAvailabilityField,
} from '../widgets/menu_availability_widget';

const reservationTypesEnumValues: EnumValueConfig[] = Object.entries(
  ReservationType
).map(([key, value]) => {
  const id = value;
  const label = key;
  return { id, label };
});

export const reservationsItemsSchema = {
  name: buildProperty<string>({
    name: 'Reservation Name',
    validation: { required: true, trim: true, max: 30 },
    dataType: 'string',
  }),
  shortDescription: buildProperty<string>({
    name: 'Description',
    validation: { required: false, trim: true, max: 100 },
    dataType: 'string',
  }),
  longDescription: buildProperty<string>({
    name: 'Long Description',
    validation: { required: false, trim: true, max: 400 },
    dataType: 'string',
  }),
  type: buildProperty<string>({
    name: 'Reservation type',
    validation: { required: true },
    dataType: 'string',
    enumValues: reservationTypesEnumValues,
  }),
  pricePerHourInXAF: buildProperty<number>({
    name: 'Price Per Hour (XAF)',
    dataType: 'number',
    validation: {
      required: false,
    },
  }),
  pricePerDayInXAF: buildProperty<number>({
    name: 'Price Per Day (XAF)',
    dataType: 'number',
    validation: {
      required: false,
    },
  }),
  imagePath: buildProperty<string>({
    name: 'Image',
    validation: { required: false },
    dataType: 'string',
    storage: {
      storagePath: 'restaurants/reservation_items',
      acceptedFiles: ['image/*'],
    },
  }),
  isAvailable: buildProperty<boolean>({
    name: 'Is Available',
    dataType: 'boolean',
    validation: { required: true },
  }),
  isVisible: buildProperty<boolean>({
    name: 'Is Visible',
    dataType: 'boolean',
    defaultValue: true,
    validation: { required: true },
  }),
  categoriesPaths: buildProperty<Array<string>>({
    name: 'Categories',
    dataType: 'array',
    of: {
      dataType: 'string',
    },
    validation: { required: true },
    Field: CategoriesPathFields,
    Preview: CategoriesPathPreView,
  }),
  availabilitySchedule: buildProperty<Partial<AvailabilitySchedule>>({
    name: 'Availability Schedule',
    dataType: 'map',
    Field: CustomAvailabilityField,
    Preview: AvailabilityDays,
  }),
};
