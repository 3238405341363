import { buildProperty } from 'firecms';

export const achievementsRewardsOverridesSchema = {
  CUSTOMER_REFERRED_CUSTOMER: buildProperty<number>({
    name: 'CUSTOMER_REFERRED_CUSTOMER',
    description: 'Rewards (in coins) for referring a customer',
    dataType: 'number',
    validation: {
      required: true,
    },
    readOnly: false,
    disabled: false,
  }),
  REFERRED_CUSTOMER_COMPLETED_AN_ORDER: buildProperty<number>({
    name: 'REFERRED_CUSTOMER_COMPLETED_AN_ORDER',
    description: 'Rewards (in coins) when a referral completes an order',
    dataType: 'number',
    validation: {
      required: true,
    },
    readOnly: false,
    disabled: false,
  }),
};
