import { Box, Chip } from '@mui/material';
import { PropertyPreviewProps } from 'firecms';
import { PartnerPlace } from '../models/partner';
import { CHIP_COLORS } from '../utils/pick_up_a_color';

export function PartnerPlacesPreview({
  value,
}: PropertyPreviewProps<Partial<Record<string, unknown>>>) {
  if (!value) {
    return <Box />;
  }

  const partnerPlaces = value as Record<string, PartnerPlace>;

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(0.5),
      })}
    >
      {Object.keys(partnerPlaces).map((key) => {
        const colorKey = Object.keys(CHIP_COLORS);
        const color = Math.floor(Math.random() * colorKey.length);
        const usedColorScheme = CHIP_COLORS[colorKey[color]];
        return (
          <Chip
            key={`${key}`}
            sx={{
              backgroundColor: usedColorScheme.color,
              color: usedColorScheme.text,
            }}
            size={'medium'}
            variant={'filled'}
            label={key}
          />
        );
      })}
    </Box>
  );
}
