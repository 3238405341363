import { User } from 'firecms';

const superAdmins = [
  'gilles.madi@spreeloop.com',
  'steeve.cabrel@spreeloop.com',
  'pat@spreeloop.com',
];

const developers = [
  'ludovic.feutse@spreeloop.com',
  'warren.rhodes@spreeloop.com',
  ...superAdmins,
];

const placeActivationManagers = [
  'chancia.akamba@spreeloop.com',
  ...developers,
];

function isManager(user: User | null, managers: string[]): boolean {
  const email = user?.email;
  return email ? managers.includes(email) : false;
}

export function isSpreeloopCoinsManager(user: User | null): boolean {
  return isManager(user, superAdmins);
}

export function isWithdrawalManager(user: User | null): boolean {
  return isManager(user, superAdmins);
}

export function isAccountManager(user: User | null): boolean {
  return isManager(user, superAdmins);
}

export function isAuditManager(user: User | null): boolean {
  return isManager(user, superAdmins);
}

export function isSpreeloopDeveloper(user: User | null): boolean {
  return isManager(user, developers);
}

export function isPlaceActivationManager(user: User | null): boolean {
  return isManager(user, placeActivationManagers);
}

export function isSpreeloopUser(user: User | null): boolean {
  const email = user?.email;
  return email ? email.endsWith('@spreeloop.com') : false;
}
