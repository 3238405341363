import { buildCollection } from 'firecms';
import { achievementsRewardsOverridesSchema } from '../schemas/achievements_rewards_override_schema';
import { isSpreeloopCoinsManager } from '../utils/users_verification';

export const achievementsRewardsOverridesCollection = buildCollection({
  path: 'achievementsRewardsOverrides',
  customId: true,
  properties: achievementsRewardsOverridesSchema,
  name: 'Rewards Overrides',
  permissions: ({ authController }) => ({
    edit: isSpreeloopCoinsManager(authController.user),
    create: isSpreeloopCoinsManager(authController.user),
    delete: isSpreeloopCoinsManager(authController.user),
  }),
});
