import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  List,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { FieldProps, PropertyPreviewProps } from 'firecms';
import { useCallback, useEffect, useState } from 'react';
import { PlaceTable } from '../models/place';
import { generateUUID } from '../utils/generate_uuid';

interface Column {
  id: 'tableName' | 'deepLink' | 'qrCode';
  label: string;
}

const columns: readonly Column[] = [
  { id: 'tableName', label: 'TableName' },
  { id: 'deepLink', label: 'DeepLink' },
  { id: 'qrCode', label: 'Qr Code' },
];

export function TablesPreView({
  value,
}: PropertyPreviewProps<Partial<Record<string, unknown>>>) {
  const [open, setOpen] = useState(false);

  const handleClickSetQrCode = (webDeepLink?: string | null) => {
    if (!webDeepLink) {
      return;
    }
    window.open(
      `https://quickchart.io/qr?text=${webDeepLink}&margin=4&size=400`,
      '_blank'
    );
    return;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!value) {
    return <Box />;
  }

  const tables = value as Record<string, PlaceTable>;

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(0.5),
      })}
    >
      <Button variant="outlined" onClick={handleClickOpen}>
        DineIn Table
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={'center'}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(tables).map(([key, table]) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{table.tableName}</TableCell>
                      <TableCell align="center">{table.webDeepLink}</TableCell>
                      <TableCell align="center">
                        {table.webDeepLink ? (
                          <Button
                            variant="contained"
                            sx={{ marginY: '10px' }}
                            onClick={() =>
                              handleClickSetQrCode(table.webDeepLink)
                            }
                          >
                            Display QrCode
                          </Button>
                        ) : (
                          <Box />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export function TablesFields({
  value,
  isSubmitting,
  setValue,
  showError,
  error,
}: FieldProps<Partial<Record<string, unknown>>>) {
  const [placeTable, setPlaceTable] = useState<
    Record<string, PlaceTable> | undefined
  >(value as Record<string, PlaceTable> | undefined);

  /**
   * Adds a new dineIn table.
   */
  const addNewDineInTable = () => {
    setPlaceTable({
      ...placeTable,
      [generateUUID()]: {
        tableName: '',
      },
    });
  };

  /**
   * Updates a dineIn table.
   */
  const updateDineInTable = (value: string, tableKey: string) => {
    if (!placeTable) return;
    placeTable[tableKey].tableName = value;
  };

  /**
   * Updates the place table on submitting.
   * @returns { void }.
   */
  const updatePlaceTable = useCallback(async () => {
    if (placeTable) {
      const tables = Object.values(placeTable);
      for (let i = 0; i < tables.length; i++) {
        const name = tables[i].tableName;
        if (!name || name.trim().length === 0) {
          return;
        }

        for (let j = i + 1; j < tables.length; j++) {
          if (tables[i].tableName === tables[j].tableName) {
            return;
          }
        }
      }

      setValue(
        placeTable && Object.values(placeTable).length !== 0 ? placeTable : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeTable]);

  useEffect(() => {
    if (isSubmitting) {
      updatePlaceTable();
    }
  }, [isSubmitting, updatePlaceTable]);

  return (
    <Paper variant="outlined" sx={{ width: '100%', padding: '12px' }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {placeTable ? (
          Object.entries(placeTable).map(([key, value]) => {
            return (
              <Card
                key={key}
                sx={{
                  margin: '10px',
                  padding: '10px',
                }}
              >
                <Stack direction={'row'} alignItems={'center'}>
                  <FormControl error={!!error}>
                    <TextField
                      key={value.tableName}
                      id="outlined-basic"
                      label="Table Name"
                      variant="outlined"
                      defaultValue={value.tableName}
                      required
                      onChange={(value) =>
                        updateDineInTable(value.target.value, key)
                      }
                    />
                    {showError && <FormHelperText>{error}</FormHelperText>}
                  </FormControl>
                </Stack>
              </Card>
            );
          })
        ) : (
          <Box />
        )}
      </List>
      <Button
        variant="outlined"
        color="primary"
        size="medium"
        startIcon={<AddIcon />}
        onClick={addNewDineInTable}
      >
        Add
      </Button>
    </Paper>
  );
}
