import { buildProperty } from 'firecms';

enum ReviewStatus {
  active = 'ACTIVE',
  suspended = 'SUSPENDED',
  unknown = 'UNKNOWN',
  pending = 'PENDING',
  done = 'DONE',
}

export const orderReviewSchema = {
  serviceQuality: buildProperty<number>({
    readOnly: true,
    name: 'Service Quality',
    dataType: 'number',
    validation: { required: false },
  }),
  punctualityQuality: buildProperty<number>({
    readOnly: true,
    name: 'Punctuality',
    dataType: 'number',
    validation: { required: false },
  }),
  foodQuality: buildProperty<number>({
    readOnly: true,
    name: 'Food Quality',
    dataType: 'number',
    validation: { required: false },
  }),
  packagingQuality: buildProperty<number>({
    readOnly: true,
    name: 'Packaging',
    dataType: 'number',
    validation: { required: false },
  }),
  review: buildProperty<string>({
    readOnly: true,
    name: 'Message',
    dataType: 'string',
    validation: { required: false },
  }),
  userPath: buildProperty<string>({
    readOnly: true,
    name: 'User Path',
    dataType: 'string',
    validation: { required: false },
  }),
  status: buildProperty<string>({
    name: 'Status',
    dataType: 'string',
    enumValues: ReviewStatus,
    validation: { required: false },
  }),
  orderReviewOverallScore: buildProperty<number>({
    readOnly: true,
    name: 'Overall Score',
    dataType: 'number',
    validation: { required: false },
  }),
  createdAt: buildProperty<string>({
    readOnly: true,
    name: 'Creation Date ISO Format',
    description: 'e.g: 2021-12-27T20:50:58.116137Z',
    dataType: 'string',
    validation: { required: false },
  }),
};
