import { buildCollection } from 'firecms';
import { CategoriesSchema } from '../schemas/category_schema';
import {
  isSpreeloopDeveloper,
  isSpreeloopUser,
} from '../utils/users_verification';

export const categoryCollection = buildCollection({
  path: 'categories',
  properties: CategoriesSchema,
  name: 'Categories',
  permissions: ({ authController }) => ({
    edit: isSpreeloopUser(authController.user),
    create: isSpreeloopUser(authController.user),
    delete: isSpreeloopDeveloper(authController.user),
  }),
});
